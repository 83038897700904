import {useUserData} from "../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import Util from "../util/util";
import {formatDate, formatTypes} from "../../helpers/date";
import {useEffect} from "react";
import {calculateNextRouteDate} from "../../helpers/helpers";

const RouteCalculator = ({setNextRouteDate, nextRouteDate}) => {
    const {data} = useUserData()
    const {t} = useTranslation(PAGE_ORDERS)
    const routeStartDate = data.routeStartDate;
    const routeFrequency = data.routeRepetition;

    useEffect(() => {
        setNextRouteDate(calculateNextRouteDate(routeStartDate, routeFrequency))
    }, [routeStartDate, routeFrequency]);

    return (
        <Util align={'baseline'}>
            <strong>{t('Estimated delivery')}: </strong>
            <span>{formatDate(nextRouteDate, formatTypes.DATE_ONLY)}</span>
        </Util>
    );
};

export default RouteCalculator;