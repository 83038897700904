//@flow
import React from 'react';
import Title from "../../components/title/title";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";

const NotFound404 = () => {
    const {t} = useTranslation(PAGE_LOGIN)
    return (
        <div className={'bg-container contained-page'} style={{textAlign: "center"}}>
            <Title>
                {t('PAGE NOT FOUND')}
            </Title>
        </div>
    )
}

export default NotFound404;