// @flow
import React, {useState} from 'react';
import * as yup from 'yup'
import FormGroup from "../../components/form/formGroup/formGroup";
import TextField from "../../components/form/elements/textfield/textField";
import Button from "../../components/button/button";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import axios from "axios";
import {useUserData} from "../../hooks/user/useUserData";
import {API} from "../../helpers/constants";
import ResetPassword from "./resetPassword";
import Error from "../../components/error/error";

const ResetPasswordModal = ({setForgotPassword}) => {
    const {t} = useTranslation(PAGE_LOGIN)

    const {language} = useUserData()
    const [resetForm, setResetForm] = useState(false)
    const [error, setError]=useState(null)

    const validationSchema = yup.object({
        email: yup
            .string()
            .required(t('required'))
        //.email(t('Enter a valid email'))
    })

    const onSubmit = (values) => {
        axios({
            method: 'get',
            headers: {'Content-Type': 'application/json'},
            url: API + `/${language}/b2b/request/new-password?username=${values.email}`
        }).then(response => {
            setResetForm(true)
        }).catch(error => {
            console.log(error)
            setError(error?.response?.data?.message)
        })
    }

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })

    return (
        !resetForm ?
            <FormikProvider value={formik}>
                <FormGroup
                    id={'email'}
                    label={t('User')
                    }
                >
                    <TextField
                        type={'email'}
                        id={'email'}
                        name={'email'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    <ErrorMessage name={'email'} component={Error}/>
                    {error && <Error>{t(error)}</Error>}
                </FormGroup>
                <p>
                    {t('reset_password_message')}
                </p>
                <Button.Group>
                    <Button onClick={formik.submitForm}>
                        {t('reset')}
                    </Button>
                    <Button variant={"link"} onClick={() => setForgotPassword(false)}>
                        {t('CANCEL')}
                    </Button>
                </Button.Group>
            </FormikProvider>
            : <ResetPassword setResetForm={setResetForm} setForgotPassword={setForgotPassword}/>
    )
}

export default ResetPasswordModal;