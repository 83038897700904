import * as ActionTypes from '../actions/types'
import * as Actions from '../actions/actions'
import {requestCreator} from '../helpers/requestCreator'
import history from '../store/history'
import {getRouteUrl} from "../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDERS} from "../routers/routes";
import {PortalToken} from "../helpers/constants";

const userLogin = requestCreator({
    url: '/login',
    method: 'POST',
    onSuccess: Actions.userLoginSucceeded,
    onFailure: Actions.userLoginFailed,
    headers: {
        'Content-Type': 'multipart/form-data',
        'TokenGuid': PortalToken
    }
})

const getUserDetails = requestCreator({
    url: '/b2b/user/data',
    method: 'GET',
    onSuccess: Actions.getUserDetailsSucceeded,
    onFailure: Actions.getUserDetailsFailed,
})

const getCustomerTabInfo = requestCreator({
    url: '/b2b/customer-report',
    method: 'GET',
    onSuccess: Actions.getCustomerTabInfoSucceeded,
    onFailure: Actions.getCustomerTabInfoFailed,
})

const getReport1Info = requestCreator({
    url: '/b2b/report/bought-per-period-address-product',
    // url: 'https://212.205.70.149:8081/webservices_server/SalesNew.php',
    // nonApiUrl: true,
    method: 'GET',
    onSuccess: Actions.getReport1InfoSucceeded,
    onFailure: Actions.getReport1InfoFailed,
})

const getReport2Info = requestCreator({
    url: '/b2b/report/bought-per-period-address-product-date',
    // url: 'https://212.205.70.149:8081/webservices_server/SalesWithDateNew.php',
    // nonApiUrl: true,
    method: 'GET',
    onSuccess: Actions.getReport2InfoSucceeded,
    onFailure: Actions.getReport2InfoFailed,
})

const getReport3Info = requestCreator({
    url: '/b2b/report/compare-two-periods',
    method: 'GET',
    onSuccess: Actions.getReport3InfoSucceeded,
    onFailure: Actions.getReport3InfoFailed,
})

const getReport4Info = requestCreator({
    url: '/b2b/report/salesperson/orders',
    method: 'GET',
    onSuccess: Actions.getReport4InfoSucceeded,
    onFailure: Actions.getReport4InfoFailed,
})

const getReport5Info = requestCreator({
    url: '/b2b/report/orders-per-item',
    method: 'GET',
    onSuccess: Actions.getReport5InfoSucceeded,
    onFailure: Actions.getReport5InfoFailed,
})

const getUserStores = requestCreator({
    url: '/b2b/user/addresses',
    method: 'GET',
    onSuccess: Actions.getUserStoresSucceeded,
    onFailure: Actions.getUserStoresFailed,
})

const getOrders = requestCreator({
    url: '/b2b/user/orders',
    method: 'GET',
    onSuccess: Actions.getOrdersSucceeded,
    onFailure: Actions.getOrdersFailed,
})

const getOrderTemplates = requestCreator({
    url: '/b2b/user/orders',
    method: 'GET',
    onSuccess: Actions.getOrderTemplatesSucceeded,
    onFailure: Actions.getOrderTemplatesFailed,
})

const getOrderDetails = requestCreator({
    url: '/b2b/order/details/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getOrderDetailsSucceeded,
    onFailure: Actions.getOrderDetailsFailed,
})

const getActiveOrder = requestCreator({
    url: '/b2b/user/orders',
    method: 'GET',
    onSuccess: Actions.getActiveOrderSucceeded,
    onFailure: Actions.getActiveOrderFailed,
})

const getProductCategories = requestCreator({
    url: '/b2b/user/ordersheet/categories/:OrderSheetCategoryId',
    hasPathParameters: ['OrderSheetCategoryId'],
    method: 'GET',
    onSuccess: Actions.getProductCategoriesSucceeded,
    onFailure: Actions.getProductCategoriesFailed,
})

const getProducts = requestCreator({
    url: '/b2b/user/ordersheets/:OrderSheetId',
    hasPathParameters: ['OrderSheetId'],
    method: 'GET',
    onSuccess: Actions.getProductsSucceeded,
    onFailure: Actions.getProductsFailed,
})

const getPopularAndNewProducts = requestCreator({
    url: '/b2b/new-and-popular',
    method: 'GET',
    onSuccess: Actions.getPopularAndNewProductsSucceeded,
    onFailure: Actions.getPopularAndNewProductsFailed,
})

const getSearchResults = requestCreator({
    url: '/b2b/search/items',
    method: 'GET',
    onSuccess: Actions.getSearchResultsSucceeded,
    onFailure: Actions.getSearchResultsFailed,
})

const getProduct = requestCreator({
    url: '/b2b/item/data/:ItemId',
    hasPathParameters: ['ItemId'],
    method: 'GET',
    onSuccess: Actions.getProductSucceeded,
    onFailure: Actions.getProductFailed,
})

const createOrder = requestCreator({
    url: '/b2b/order/store',
    method: 'POST',
    onSuccess: Actions.createOrderSucceeded,
    onFailure: Actions.createOrderFailed,
})

const cloneOrder = requestCreator({
    url: '/b2b/order/clone',
    method: 'POST',
    onSuccess: Actions.cloneOrderSucceeded,
    onFailure: Actions.cloneOrderFailed,
})

const updateOrder = requestCreator({
    url: '/b2b/order/update/:id',
    hasPathParameters: ['id'],
    method: 'POST',
    onSuccess: Actions.updateOrderSucceeded,
    onFailure: Actions.updateOrderFailed,
})

const deleteOrder = requestCreator({
    url: '/b2b/order/remove/:id',
    hasPathParameters: ['id'],
    method: 'POST',
    onSuccess: Actions.deleteOrderSucceeded,
    onFailure: Actions.deleteOrderFailed,
})

const deleteOrderSucceeded = requestCreator({
    overrideTask: async () => {
        history.push(getRouteUrl(ROUTE_PAGE_ORDERS))
    }
})

const getMessages = requestCreator({
    url: '/messages/b2bIndexTable',
    method: 'POST',
    onSuccess: Actions.getMessagesSucceeded,
    onFailure: Actions.getMessagesFailed,
})

const setMessageViewed = requestCreator({
    url: '/messages/setViewed',
    method: 'POST',
    onSuccess: Actions.setMessageViewedSucceeded,
    onFailure: Actions.setMessageViewedFailed,
})

const getMessageAttachments = requestCreator({
    url: '/messages/attachments/:id',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.getMessageAttachmentsSucceeded,
    onFailure: Actions.getMessageAttachmentsFailed,
})

const favoriteProduct = requestCreator({
    url: '/b2b/favorite/store',
    hasPathParameters: ['id'],
    method: 'GET',
    onSuccess: Actions.favoriteProductSucceeded,
    onFailure: Actions.favoriteProductFailed,
})

const getPopularPerCategory = requestCreator({
    url: '/b2b/popular-in-categories',
    method: 'GET',
    onSuccess: Actions.getPopularPerCategorySucceeded,
    onFailure: Actions.getPopularPerCategoryFailed,
})

const getOrderBalance = requestCreator({
    url: '/b2b/orders/balance',
    method: 'GET',
    onSuccess: Actions.getOrderBalanceSucceeded,
    onFailure: Actions.getOrderBalanceFailed,
})

const getBanners = requestCreator({
    url: '/b2b/dashboard/banners',
    method: 'GET',
    onSuccess: Actions.getBannersSucceeded,
    onFailure: Actions.getBannersFailed,
})

const getPayments = requestCreator({
    url: '/messages/setViewed',
    method: 'POST',
    onSuccess: Actions.getPaymentsSucceeded,
    onFailure: Actions.getPaymentsFailed,
    overrideTask: async () => ({
        data: require('./mock/payments.json')
    })
})

const getPaymentTicketingSOAP = requestCreator({
    url: '/b2b/payments/ticketing',
    method: 'POST',
    onSuccess: Actions.getPaymentTicketingSOAPSucceeded,
    onFailure: Actions.getPaymentTicketingSOAPFailed
})

const getSalesPersonUnderlings = requestCreator({
    url: '/b2b/salesperson/underlings',
    method: 'GET',
    onSuccess: Actions.getSalesPersonUnderlingsSucceeded,
    onFailure: Actions.getSalesPersonUnderlingsFailed,
})

const requestHandler = {
    [ActionTypes.USER_LOGIN]: userLogin,
    [ActionTypes.GET_USER_STORES]: getUserStores,
    [ActionTypes.GET_USER_DETAILS]: getUserDetails,
    [ActionTypes.GET_ORDERS]: getOrders,
    [ActionTypes.GET_ORDER_TEMPLATES]: getOrderTemplates,
    [ActionTypes.GET_PRODUCT_CATEGORIES]: getProductCategories,
    [ActionTypes.GET_PRODUCTS]: getProducts,
    [ActionTypes.GET_POPULAR_AND_NEW_PRODUCTS]: getPopularAndNewProducts,
    [ActionTypes.GET_PRODUCT]: getProduct,
    [ActionTypes.GET_ACTIVE_ORDER]: getActiveOrder,
    [ActionTypes.GET_ORDER_DETAILS]: getOrderDetails,
    [ActionTypes.CREATE_ORDER]: createOrder,
    [ActionTypes.CLONE_ORDER]: cloneOrder,
    [ActionTypes.UPDATE_ORDER]: updateOrder,
    [ActionTypes.DELETE_ORDER]: deleteOrder,
    [ActionTypes.DELETE_ORDER_SUCCEEDED]: deleteOrderSucceeded,
    [ActionTypes.GET_MESSAGES]: getMessages,
    [ActionTypes.GET_SEARCH_RESULTS]: getSearchResults,
    [ActionTypes.SET_MESSAGE_VIEWED]: setMessageViewed,
    [ActionTypes.GET_CUSTOMER_TAB_INFO]: getCustomerTabInfo,
    [ActionTypes.GET_REPORT_1_INFO]: getReport1Info,
    [ActionTypes.GET_REPORT_2_INFO]: getReport2Info,
    [ActionTypes.GET_REPORT_3_INFO]: getReport3Info,
    [ActionTypes.GET_REPORT_4_INFO]: getReport4Info,
    [ActionTypes.GET_REPORT_5_INFO]: getReport5Info,
    [ActionTypes.GET_MESSAGE_ATTACHMENTS]: getMessageAttachments,
    [ActionTypes.FAVORITE_PRODUCT]: favoriteProduct,
    [ActionTypes.GET_POPULAR_PER_CATEGORY]: getPopularPerCategory,
    [ActionTypes.GET_ORDER_BALANCE]: getOrderBalance,
    [ActionTypes.GET_BANNERS]: getBanners,
    [ActionTypes.GET_SALES_PERSON_UNDERLINGS]: getSalesPersonUnderlings,
    [ActionTypes.GET_PAYMENTS]: getPayments,
    [ActionTypes.GET_PAYMENT_TICKETING_SOAP]: getPaymentTicketingSOAP,

}

export default requestHandler