// @flow
import React, {useEffect, useState} from 'react';
import Images from "../../assets/images";
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import SelectStore from "../../components/selectStore/selectStore";
import './reports.css'
import List from "../../components/list/list";
import ListItem from "../../components/list/partials/listItem/listItem";
import ListItemTitle from "../../components/list/partials/listItemTitle/listItemTitle";
import MySales from "../../components/charts/mySales/mySales";
import ComparableElements from "../../components/charts/comparableElements/comparableElements";
import TopProducts from "../../components/charts/topProducts/topProducts";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
    // ROUTE_PAGE_REPORTS_1,
    // ROUTE_PAGE_REPORTS_2,
    // ROUTE_PAGE_REPORTS_3, ROUTE_PAGE_REPORTS_4,
    // ROUTE_PAGE_REPORTS_CUSTOMER_INFO
    ROUTE_PAGE_REPORTS_5
} from "../../routers/routes";
import {PAGE_REPORTS} from "../../locales/pages/namespaces";
import {useTranslation} from "react-i18next";
import PermissionHOC, {withPermissionHOC} from "../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../helpers/constants";
import TopOrders from "../../components/charts/topOrders/topOrders";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";

const Reports = () => {
    // const [selectedStore, setSelectedStore] = useState('all');
    const {t} = useTranslation(PAGE_REPORTS)
    // const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})
    // const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])
    return (
        <div className={'reports-page bg-container'}>
            <div className={'reports-page__top contained-page'}>
                <br/>
                <Util>
                    {/*<Title variant={"h2"} sup={t('title_2')}>*/}
                    <Title variant={"h2"}>
                        {t('title_1')}
                    </Title>
                    {/*{!isAdmin && !isSalesPerson && <SelectStore*/}
                    {/*    showAllOption*/}
                    {/*    updateAcrossApplication={false}*/}
                    {/*    selected={selectedStore === 'all' ? selectedStore : selectedStore.id}*/}
                    {/*    onSelection={setSelectedStore}*/}
                    {/*/>}*/}
                </Util>
                <Divider/>
                <br/>
                <div className={'reports-page__main'}>
                    <div>
                        <List dense>
                            <ListItem noBorders>
                                <ListItemTitle>
                                    <Link to={getRouteUrl(ROUTE_PAGE_REPORTS_5)}>
                                        {t('ORDERS PER ITEM')}
                                    </Link>
                                </ListItemTitle>
                            </ListItem>
                            {/*<ListItem noBorders>*/}
                            {/*    <ListItemTitle>*/}
                            {/*        <Link to={getRouteUrl(ROUTE_PAGE_REPORTS_1)}>*/}
                            {/*            {t('PERIOD SALES PER ADDRESS AND PRODUCT')}*/}
                            {/*        </Link>*/}
                            {/*    </ListItemTitle>*/}
                            {/*</ListItem>*/}
                            {/*<ListItem noBorders>*/}
                            {/*    <ListItemTitle>*/}
                            {/*        <Link to={getRouteUrl(ROUTE_PAGE_REPORTS_2)}>*/}
                            {/*            {t('PERIOD SALES PER ADDRESS, PRODUCTS AND DATE')}*/}
                            {/*        </Link>*/}
                            {/*    </ListItemTitle>*/}
                            {/*</ListItem>*/}
                            {/*<ListItem noBorders>*/}
                            {/*    <ListItemTitle>*/}
                            {/*        <Link to={getRouteUrl(ROUTE_PAGE_REPORTS_3)}>*/}
                            {/*            {t('COMPARATIVE DATA BETWEEN 2 PERIODS')}*/}
                            {/*        </Link>*/}
                            {/*    </ListItemTitle>*/}
                            {/*</ListItem>*/}
                            {/*<ListItem noBorders>*/}
                            {/*    <ListItemTitle>*/}
                            {/*        <Link to={getRouteUrl(ROUTE_PAGE_REPORTS_CUSTOMER_INFO)}>*/}
                            {/*            {t('CUSTOMER CARD')}*/}
                            {/*        </Link>*/}
                            {/*    </ListItemTitle>*/}
                            {/*</ListItem>*/}
                            {/*<PermissionHOC*/}
                            {/*    requiredPermissions={[Permissions.AccessSalesPerson]}*/}
                            {/*    notAllowedComponent={() => <span/>}*/}
                            {/*>*/}
                            {/*    <ListItem noBorders>*/}
                            {/*        <ListItemTitle>*/}
                            {/*            <Link to={getRouteUrl(ROUTE_PAGE_REPORTS_4)}>*/}
                            {/*                {t('SALES PERSON ORDERS')}*/}
                            {/*            </Link>*/}
                            {/*        </ListItemTitle>*/}
                            {/*    </ListItem>*/}
                            {/*</PermissionHOC>*/}
                        </List>
                        {/*<TopOrders*/}
                        {/*    selectedStore={selectedStore}*/}
                        {/*/>*/}
                    </div>
                    {/*<div>*/}
                    {/*    <MySales*/}
                    {/*        selectedStore={selectedStore}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </div>
            {/*<div className={'reports-page__bottom contained-page'}>*/}
            {/*    <img src={Images.pages.orders.secondaryBackground} alt="background"/>*/}
            {/*    <div>*/}
            {/*        <ComparableElements*/}
            {/*            selectedStore={selectedStore}*/}
            {/*        />*/}
            {/*        <TopProducts*/}
            {/*            selectedStore={selectedStore}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

// export default withPermissionHOC(Reports, [Permissions.AccessReports])
export default Reports