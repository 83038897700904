//@flow
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import classNames from "classnames";
import './menu.old.css'
import Icon from "../../icon/icon";
import Images from "../../../assets/images";
import Button from "../../button/button";
import Title from "../../title/title";
import useProductAction from "../../../hooks/product/useProductAction";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../../helpers/getRouteUrl";
import {
    ROUTE_PAGE_ADMIN_ADDRESSES,
    ROUTE_PAGE_ANNOUNCEMENTS,
    ROUTE_PAGE_ONLINE_PAYMENTS,
    ROUTE_PAGE_ORDERS,
    ROUTE_PAGE_PRODUCT_CATEGORY,
    ROUTE_PAGE_PRODUCT_CATEGORY_PARENT,
    ROUTE_PAGE_REPORTS
} from "../../../routers/routes";
import {useTranslation} from "react-i18next";
import {CLONE_ORDER_COMPONENT, HEADER_COMPONENT} from "../../../locales/components/namespaces";
import Util from "../../util/util";
import SelectStore from "../../selectStore/selectStore";
import CreateOrder from "../../createOrder/createOrder";
import useUserAction from "../../../hooks/user/useUserAction";
import _ from "lodash";
import {useHistory} from "react-router";
import useProductCategoryDetailsData from "../../../hooks/product/useProductCategoryDetailsData";
import PermissionHOC from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../helpers/constants";
import {useHasPermission} from "../../../modules/permission/hooks/useHasPermission";
import SelectedStoreAdmin from "../../selectedStoreAdmin/selectedStoreAdmin";

type Props = {
    children: React.Node,
    onClose?: Function,
    show?: boolean,
    menuCategoryType: number,
}


const Menu = (props: Props) => {
    const {t} = useTranslation(CLONE_ORDER_COMPONENT)
    const {show, onClose, menuCategoryType} = props
    const [openSubmenu, setOpenSubmenu] = useState(null)
    const {getProductCategories} = useProductAction()
    const {all: productCategories, loading} = useProductCategoryDetailsData({typeId: menuCategoryType})
    const history = useHistory()
    const goToTop = useCallback(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])
    useEffect(() => {
        getProductCategories()
    }, [getProductCategories])

    useEffect(() => {
        if (!show) {
            setOpenSubmenu(null)
        }
    }, [show])

    if (!show) return null;
    return (
        <div
            onClick={onClose}
            className={classNames('menu-old', {
                'menu-old--visible': show
            })}
        >
            <section
                className={'menu-old__content'}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <div className={'menu-old__container menu-old__container--first'}>
                    {/*<img className={'background'} src={Images.menu.corner_3} alt="background 3"/>*/}
                    <div className={'links'}>
                        <Title variant={"h4"} onClick={() => {
                            history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                id: 'all',
                            }))
                            goToTop()
                            onClose()
                        }}>
                            {t('view_all_global')}
                        </Title>
                        {_.orderBy(productCategories.filter(i => !i.parent), 'weight', 'desc')
                            .map(i => (
                                <button
                                    className={'menu-old__link'}
                                    key={i.id}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        if (i.children && i.children.length > 0) {
                                            return setOpenSubmenu(i)
                                        }
                                        history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                            id: i.id,
                                        }))
                                        goToTop()
                                        onClose()
                                    }}
                                >
                                    {i.title}
                                </button>
                            ))}
                        {loading && <div>{t('loading')}...</div>}
                    </div>
                    <Button variant={"icon"} onClick={onClose} className={'close-icon'}>
                        <Icon icon={Images.icons.close}/>
                    </Button>
                </div>
                {Boolean(openSubmenu) && (
                    <div className={'menu-old__container menu-old__container--second'}>
                        {/*<img className={'background'} src={Images.menu.corner_2} alt="background 2"/>*/}
                        {/*<img className={'background'} src={Images.menu.corner_1} alt="background"/>*/}
                        <div className={'links'}>
                            <Title variant={"h4"} onClick={() => {
                                history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                    id: openSubmenu.id,
                                }))
                                goToTop()
                                onClose()
                            }}>
                                {t('view_all')} "{openSubmenu.title}"
                            </Title>
                            {_.orderBy(openSubmenu.children, 'weight', 'desc')
                                .map(i => (
                                    <Link
                                        onClick={onClose}
                                        className={'menu-old__link'}
                                        to={getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY, {
                                            id: openSubmenu.id,
                                            subCategoryId: i.id
                                        })}
                                        key={i.id}
                                    >
                                        {i.title}
                                    </Link>
                                ))}
                        </div>
                        <Button variant={"icon"} onClick={() => setOpenSubmenu(null)} className={'close-icon'}>
                            <Icon icon={Images.icons.close}/>
                        </Button>
                    </div>
                )}
            </section>
        </div>
    )
}

const MenuMobile = (props: Props) => {
    const {t} = useTranslation([CLONE_ORDER_COMPONENT, HEADER_COMPONENT])
    const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})
    const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})
    const {show, onClose, menuCategoryType} = props
    const [showProducts, setShowProducts] = useState(false)
    const [showProductsSubmenu, setShowProductsSubmenu] = useState(null)
    const {getProductCategories} = useProductAction()
    const {all: productCategories, loading} = useProductCategoryDetailsData({typeId: menuCategoryType})
    const {userLogout} = useUserAction()
    const history = useHistory()
    const goToTop = useCallback(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])
    useEffect(() => {
        getProductCategories()
    }, [getProductCategories])

    if (!show) return null;
    return (
        <div
            onClick={onClose}
            className={classNames('menu-old', {
                'menu-old--visible': show,
                'menu-old--mobile': true,
            })}
        >
            <section
                className={'menu-old__content'}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                <div className={'menu-old__container menu-old__container--first'}>
                    <Util>
                        <Button variant={"icon"} onClick={onClose} className={'close-icon'}>
                            <Icon icon={Images.icons.close}/>
                        </Button>
                        <div className={'select-store-wrapper'}>
                            {!isAdmin && !isSalesPerson && <SelectStore/>}
                        </div>
                        <div>{(isAdmin || isSalesPerson) && <SelectedStoreAdmin/>}</div>
                    </Util>
                    <div className={'links'}>
                        <Title variant={"h4"}>
                            {t(HEADER_COMPONENT + ':menu')}
                        </Title>
                        <Link onClick={onClose} className={'menu-old__link'} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                            <span>{t(HEADER_COMPONENT + ':orders')}</span>
                        </Link>
                        <button className={'menu-old__link'} onClick={() => setShowProducts(true)}>
                            <span>{t(HEADER_COMPONENT + ':products')}</span>
                        </button>
                        {/*<Link onClick={onClose} className={'menu-old__link'} to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS)}>*/}
                        {/*    <span>{t(HEADER_COMPONENT + ':news')}</span>*/}
                        {/*</Link>*/}
                        <PermissionHOC
                            requiredPermissions={[Permissions.AccessReports]}
                            notAllowedComponent={() => <span/>}
                        >
                            <Link onClick={onClose} className={'menu-old__link'} to={getRouteUrl(ROUTE_PAGE_REPORTS)}>
                                <span>{t(HEADER_COMPONENT + ':reports')}</span>
                            </Link>
                        </PermissionHOC>
                        {/*<PermissionHOC*/}
                        {/*    requiredPermissions={[Permissions.AccessPayments]}*/}
                        {/*    notAllowedComponent={() => <span/>}*/}
                        {/*>*/}
                        {/*    <Link onClick={onClose} className={'menu-old__link'}*/}
                        {/*          to={getRouteUrl(ROUTE_PAGE_ONLINE_PAYMENTS)}>*/}
                        {/*        {t(HEADER_COMPONENT + ':payments_1')}{' '}{t(HEADER_COMPONENT + ':payments_2')}*/}
                        {/*    </Link>*/}
                        {/*</PermissionHOC>*/}
                        {/*<PermissionHOC*/}
                        {/*    requiredPermissions={[Permissions.AccessAdmin]}*/}
                        {/*    notAllowedComponent={() => <span/>}*/}
                        {/*>*/}
                        {/*    <Link onClick={onClose} className={'menu-old__link'}*/}
                        {/*          to={getRouteUrl(ROUTE_PAGE_ADMIN_ADDRESSES)}>*/}
                        {/*        <span>{t(HEADER_COMPONENT + ':admin_addresses')}</span>*/}
                        {/*    </Link>*/}
                        {/*</PermissionHOC>*/}
                        <button
                            className={'menu-old__link'}
                            onClick={userLogout}>
                            {t(HEADER_COMPONENT + ':logout')}
                        </button>
                    </div>
                    <CreateOrder showOnlyFirst/>
                </div>
                {showProducts && (
                    <div className={'menu-old__container menu-old__container--first'}>
                        {/*<img className={'background'} src={Images.menu.corner_3} alt="background 3"/>*/}
                        <Util>
                            <Button variant={"icon"} onClick={onClose}>
                                <Icon icon={Images.icons.close}/>
                            </Button>
                            <Button variant={'icon'} onClick={() => {
                                setShowProducts(false)
                            }}>
                                <Icon icon={Images.icons.arrowLeft}/> {t('BACK')}
                            </Button>
                        </Util>
                        <div className={'links'}>
                            <Title variant={"h4"} onClick={() => {
                                history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                    id: 'all',
                                }))
                                goToTop()
                                onClose()
                            }}>
                                {t('view_all_global')}
                            </Title>
                            {_.orderBy(productCategories.filter(i => !i.parent), 'weight', 'desc')
                                .map(i => (
                                    <button
                                        className={'menu-old__link'}
                                        key={i.id}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (i.children && i.children.length > 0) {
                                                return setShowProductsSubmenu(i)
                                            }
                                            history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                                id: i.id,
                                            }))
                                            onClose()
                                        }}
                                    >
                                        {i.title}
                                    </button>
                                ))}
                            {loading && <div>{t('loading')}...</div>}
                        </div>
                    </div>
                )}
                {Boolean(showProductsSubmenu) && (
                    <div className={'menu-old__container menu-old__container--second'}>
                        {/*<img className={'background'} src={Images.menu.corner_2} alt="background 2"/>*/}
                        {/*<img className={'background'} src={Images.menu.corner_1} alt="background"/>*/}
                        <Util>
                            <Button variant={"icon"} onClick={onClose}>
                                <Icon icon={Images.icons.close}/>
                            </Button>
                            <Button variant={'icon'} onClick={() => setShowProductsSubmenu(null)}>
                                <Icon icon={Images.icons.arrowLeft}/> {t('BACK')}
                            </Button>
                        </Util>
                        <div className={'links'}>
                            <Title variant={"h4"} onClick={() => {
                                history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                    id: showProductsSubmenu.id,
                                }))
                                onClose()
                                goToTop()
                            }}>
                                {t('view_all')} "{showProductsSubmenu.title}"
                            </Title>
                            {_.orderBy(showProductsSubmenu.children, 'weight', 'desc')
                                .map(i => (
                                    <Link
                                        onClick={onClose}
                                        className={'menu-old__link'}
                                        to={getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY, {
                                            id: showProductsSubmenu.id,
                                            subCategoryId: i.id
                                        })}
                                        key={i.id}
                                    >
                                        {i.title}
                                    </Link>
                                ))}
                        </div>
                    </div>
                )}
            </section>
        </div>
    )
}

Menu.Mobile = MenuMobile

export default Menu;