//@flow
import React from 'react';
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";

const Terms = () => {
    return (
        <div className={'bg-container bg-container--transparent contained-page'}>
            <Title>TERMS OF USE</Title>
            <Divider/>
            <p>
                Α. Όροι και Προϋποθέσεις Χρήσης<br/><br/>
                ...
            </p>
        </div>
    );
};

export default Terms;