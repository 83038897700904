//@flow
import {useEffect, useState} from "react";
import {useOrderData} from "../order/useOrderData";
import {getDiscountQuantity} from "../../helpers/helpers";

const useProductQuantity = (product, orderType, orderId = null) => {
    const [quantity, setQuantity] = useState(0)
    const discountQuantity = getDiscountQuantity(product, quantity)
    const {activeOrder} = useOrderData()
    const activeOrderOfType = activeOrder.find(o => o.type === orderType)

    useEffect(() => {
        if (!orderId && activeOrderOfType) {
            const {products: activeOrderProducts} = activeOrderOfType
            const productInActiveOrder = activeOrderProducts.find(p => p.id === product.id)
            if (productInActiveOrder) {
                const newQuantity = Number(productInActiveOrder.quantity)
                setQuantity(newQuantity)
            }
        }
    }, [activeOrderOfType?.id, activeOrderOfType?.products, product.id])

    useEffect(() => {
        if (product.quantity) {
            setQuantity(product.quantity)
        }
    }, [product.quantity])

    return {
        quantity,
        discountQuantity,
        setQuantity,
        activeOrderOfType,
    }
}

export default useProductQuantity