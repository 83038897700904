//@flow
import React, {Fragment, useEffect, useState} from 'react';
import "./orderCreate.css"
import Form from "../../components/order/form/form";
import Overview from "../../components/order/overview/overview";
import {Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router";
import useOrderAction from "../../hooks/order/useOrderAction";
import useOrderDetailsData from "../../hooks/order/useOrderDetailsData";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER, ROUTE_PAGE_ORDER_EDIT, ROUTE_PAGE_ORDER_PLACED} from "../../routers/routes";
import {useOrderData} from "../../hooks/order/useOrderData";
import Loading from "../../components/loading/loading";
import {OrderStatus} from "../../helpers/constants";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import Divider from "../../components/divider/divider";
import Title from "../../components/title/title";
import Button from "../../components/button/button";
import {Link} from "react-router-dom";

const OrderEdit = () => {
    const {t} = useTranslation(PAGE_ORDERS)
    const {updateOrder, deleteOrder} = useOrderAction()
    const {path} = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const {id} = useParams()
    const {getOrderDetails} = useOrderAction()
    const {order} = useOrderDetailsData(id)
    const {loading} = useOrderData()
    const [nextRouteDate, setNextRouteDate] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])

    useEffect(() => {
        getOrderDetails(id)
    }, [getOrderDetails, id])

    const handleSubmitForm = (values) => {
        const newOrder = {
            ...order,
            ...values
        }
        updateOrder(id, newOrder)
        history.push(getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {id: id}) + '/finalize', {order: newOrder})
    }

    const handleDelete = () => {
        deleteOrder(id)
    }

    const handleSendOrder = (values) => {
        const newOrder = {
            ...order,
            ...values,
            nextRouteDate: nextRouteDate
        }
        updateOrder(id, {
            ...newOrder,
            statusId: OrderStatus.Sent
        })
        history.push(getRouteUrl(ROUTE_PAGE_ORDER_PLACED, {id: id}))
    }

    return (
        <Fragment>
            <Loading show={loading}/>
            {order && order.statusId === OrderStatus.Change ? (
                <Switch>
                    <Route exact path={path + '/finalize'}>
                        {location.state && location.state.order ? (
                            <Overview
                                order={order}
                                onSubmit={handleSendOrder}
                                onGoBack={() => history.goBack()}
                                setNextRouteDate={setNextRouteDate}
                                nextRouteDate={nextRouteDate}
                            />
                        ) : (
                            <Redirect to={getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {id: id})}/>
                        )}
                    </Route>
                    <Route exact path={path}>
                        <Form
                            order={order}
                            onSubmit={handleSubmitForm}
                            onDelete={handleDelete}
                            setNextRouteDate={setNextRouteDate}
                            nextRouteDate={nextRouteDate}
                        />
                    </Route>
                </Switch>
            ) : !loading ? (
                <div className={'bg-container bg-container--transparent contained-page'}>
                    <Title>
                        {t('order_submitted_title')}
                    </Title>
                    <Divider/>
                    <p>
                        {t('order_submitted_message')}
                    </p>
                    <Button.Group spacingTop={1}>
                        <Button
                            component={Link}
                            to={getRouteUrl(ROUTE_PAGE_ORDER, {id: id})}
                        >
                            {t('show')} {t('send_2')}
                        </Button>
                    </Button.Group>
                </div>
            ) : null}
        </Fragment>
    )
}

export default OrderEdit;