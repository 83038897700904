// @flow
import React, {useEffect, useMemo, useState} from 'react';
import Divider from "../../components/divider/divider";
import {useParams} from "react-router";
import ActiveOrder from "../../components/activeOrder/activeOrder";
import useProductCategoryDetailsData from "../../hooks/product/useProductCategoryDetailsData";
import useProductAction from "../../hooks/product/useProductAction";
import "./productCategory.css"
import Images from "../../assets/images";
import ProductsList from "../../components/productsList/productsList";
import useProductData from "../../hooks/product/useProductData";
import Loading from "../../components/loading/loading";
import {useOrderData} from "../../hooks/order/useOrderData";
import SearchInput from "../../components/searchInput/searchInput";
import useSearchQuery from "../../hooks/useSearchQuery";
import {useTranslation} from "react-i18next";
import {SEARCH_COMPONENT} from "../../locales/components/namespaces";
import useOrderCreate from "../../hooks/order/useOrderCreate";
import Breadcrumb from "./breadcrumb";
import UnstructuredTableView from "../../components/table/partials/views/unstructuredTableView";
import {getMatches} from "../../components/highlightedString/highlightedString";
import type {Product} from "../../types/product/types";
import Util from "../../components/util/util";
import CategoryFilter from "./categoryFilter";

const ProductCategory = () => {
    const {t} = useTranslation(SEARCH_COMPONENT)
    const {query, setQuery} = useSearchQuery()
    const {subCategoryId: paramsSubCategoryId, id: paramsId} = useParams()
    const [categoryFilter, setCategoryFilter] = useState({
        categoryId: null,
        subCategoryId: null
    })
    const id = categoryFilter.categoryId ?? paramsId
    const subCategoryId = categoryFilter.categoryId ? categoryFilter.subCategoryId : paramsSubCategoryId
    const {getProducts} = useProductAction()
    const {allIndexTable} = useProductCategoryDetailsData({id, subCategoryId})
    const {loading: loadingProduct} = useProductData()
    const {loading: loadingOrder} = useOrderData()
    const {activeOrderOfType} = useOrderCreate()
    useEffect(() => {
        setQuery('')
    }, [id, setQuery, subCategoryId])
    const data = useMemo(() => ({
        items: (query && query !== '') ?
            allIndexTable.items.filter(i => {
                const matchesCode = getMatches(i.code, query)
                const matchesTitle = getMatches(i.title, query)
                return (
                    (matchesCode && matchesCode.length > 0) ||
                    (matchesTitle && matchesTitle.length > 0)
                )
            }) :
            id !== 'all' ?
                allIndexTable.items.filter((i: Product) => (
                    i.category.id === id &&
                    (!subCategoryId || (subCategoryId && i.subCategory.id === subCategoryId))
                )) :
                allIndexTable.items,
        count: allIndexTable.count,
        loading: allIndexTable.loading,
    }), [allIndexTable.count, allIndexTable.items, allIndexTable.loading, id, query, subCategoryId])
    return (
        <div className={'bg-container product-category'}>
            <Loading show={loadingOrder || loadingProduct}/>
            <div className={'product-category-order'}>
                {/*<img*/}
                {/*    src={Images.pages.category.activeOrder}*/}
                {/*    alt="background"*/}
                {/*    className={'product-category-order-image'}*/}
                {/*/>*/}
                <ActiveOrder/>
            </div>
            <div className={'contained-page'}>
                <div className={'product-category-title'}>
                    <Util className={'filter-wrapper'} style={{marginBottom: '1rem'}}>
                        <SearchInput
                            placeholder={
                                activeOrderOfType ?
                                    t('search_local', {orderType: activeOrderOfType.typeDescription})
                                    : null
                            }
                            query={query}
                            onChange={setQuery}
                            filled
                        />
                        <CategoryFilter
                            categoryFilter={categoryFilter}
                            onChangeCategory={(id) => {
                                setCategoryFilter({
                                    categoryId: id,
                                    subCategoryId: null
                                })
                            }}
                            onChangeSubCategory={(categoryId, subCategoryId) => {
                                setCategoryFilter({
                                    categoryId: categoryId,
                                    subCategoryId: subCategoryId
                                })
                            }}
                        />
                    </Util>
                    <Breadcrumb/>
                    <Divider/>
                </div>
                <ProductsList
                    query={query}
                    dataManager={getProducts}
                    data={data}
                    view={UnstructuredTableView}
                    showCategoryTitle={Boolean(!subCategoryId)}
                    showGoToTop
                    noPagination
                    noMeta
                    virtualized
                />
            </div>
        </div>
    )
}

export default ProductCategory