//@flow
import React, {useState} from 'react';
import FormGroup from "../../components/form/formGroup/formGroup";
import TextField from "../../components/form/elements/textfield/textField";
import Button from "../../components/button/button";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {PAGE_LOGIN} from "../../locales/pages/namespaces";
import {useUserData} from "../../hooks/user/useUserData";
import * as yup from "yup";
import axios from "axios";
import {API} from "../../helpers/constants";
import Password from "../../components/form/elements/password/password";
import Error from "../../components/error/error";

const ResetPassword = ({setResetForm, setForgotPassword}) => {

    const {t} = useTranslation(PAGE_LOGIN)
    const {language} = useUserData()
    const [error, setError]=useState(null)

    const validationSchema = yup.object({
       /* password: yup
            .string()
            .required(t('required')),
        confirmPassword: yup
            .string()
            .required(t('required')),*/
        password: yup.string()
            .required(t('required'))
            .matches(/^.{60}$|(^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z\d])|(?=.*?[A-Z])(?=.*?\d)(?=.*?[^a-zA-Z\d])|(?=.*?[a-z])(?=.*?\d)(?=.*?[^a-zA-Z\d])).{7,16}$)/,
                t('Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character')),
        confirmPassword: yup.string()
            .required(t('required'))
            .oneOf([yup.ref('password'), null], t('Passwords must match')),
        oneTimePassword: yup
            .string()
            .required(t('required')),
    })

    const onSubmit = (values) => {
        setError(null)
        axios({
            method: 'post',
            data: {
                oneTimePassword: values.oneTimePassword,
                password: values.password,
            },
            headers: {'Content-Type': 'application/json'},
            url: API + `/${language}/b2b/store/new/password`
        }).then(response => {
            console.log(response)
            setForgotPassword(false)
            setResetForm(false)
        }).catch(error => {
            // debugger
            // console.log(error?.response?.data?.message)
            setError(error?.response?.data?.message)
        })
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            oneTimePassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
    })
    return (
        <FormikProvider value={formik}>
            <FormGroup
                id={'oneTimePassword'}
                label={t('Six digit code')
                }
            >
                <TextField
                    id={'oneTimePassword'}
                    name={'oneTimePassword'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.oneTimePassword}
                />
                <ErrorMessage name={'oneTimePassword'} component={Error}/>
            </FormGroup>
            <FormGroup
                label={t('Password')}
                id={'password'}
            >
                <Password
                    id={'password'}
                    name={'password'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                />
                <ErrorMessage name={'password'} component={Error}/>
            </FormGroup>
            <FormGroup
                label={t('Confirm Password')}
                id={'confirmPassword'}
            >
                <Password
                    id={'confirmPassword'}
                    name={'confirmPassword'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                />
                <ErrorMessage name={'confirmPassword'} component={Error}/>
            </FormGroup>
            {error && <Error>{t(error)}</Error>}
            <br/>
            <Button.Group>
                <Button onClick={formik.submitForm}>
                    {t('reset')}
                </Button>
                <Button variant={"link"} onClick={() => setResetForm(false)}>
                    {t('CANCEL')}
                </Button>
            </Button.Group>
        </FormikProvider>
    )
}

export default ResetPassword;