import type {Order} from "./types";
import {parseMultipleFromReference} from "../../helpers/parse";
import {parseProduct} from "../product/parse";

const parseAddress = (datum) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        address: datum.get('address'),
        city: datum.get('city'),
        postalCode: datum.get('postalCode'),
        countryCode: datum.get('countryCode')
    }
}

const parseOrderProduct = (datum, language, byId, datumOriginal) => {
    const item = parseProduct(datum, language, byId)
    return {
        ...item,
        quantity: datumOriginal.get('quantity'),
        ...(datumOriginal.has('priceWithTax') ? {
            priceWithTax: datumOriginal.get('priceWithTax')
        } : {}),
        ...(datumOriginal.has('priceWithoutTax') ? {
            priceWithoutTax: datumOriginal.get('priceWithoutTax')
        } : {})
    }
}

export const parseOrder: Order = (datum, language, productsById) => {
    // TODO::Πάρε την ημερομηνία από το store
    if (!datum) return;
    const products = parseMultipleFromReference(datum.get('products'), productsById, (item, originalItem) => parseOrderProduct(item, language, productsById, originalItem), 'id')
    return {
        id: datum.get('id'),
        transferredDate: datum.get('transferredDate'),
        transitionDate: datum.get('transitionDate'),
        type: datum.get('type'),
        typeDescription: datum.getIn(['typeDescription', language]),
        date: datum.get('date'),
        price: datum.get('price'),
        status: datum.get('status'),
        statusId: datum.get('statusId'),
        title: datum.get('title'),
        code: datum.get('code'),
        notes: datum.get('notes'),
        store: parseAddress(datum.get('store')),
        billing: parseAddress(datum.get('billing')),
        shipping: parseAddress(datum.get('shipping')),
        products,
        acceptBalance: datum.get('acceptBalance'),
        orderSheetId:  datum.get('orderSheetId'),
        orderSheetIsInternal: datum.get('orderSheetIsInternal'),
        nextRouteDate: datum.get('nextRouteDate'),
    }
}