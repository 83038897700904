import {useUserData} from "../../hooks/user/useUserData";
import {createRef} from "react";

export function AuthenticatedLink({url, filename, children, ...otherProps}) {
    const link = createRef()
    const {token} = useUserData()
    const headers = {'Authorization': `Bearer ${token}`}
    const handleAction = async () => {
        if (link.current.href) {
            return
        }
        const result = await fetch(url, {
            headers: headers
        })
        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)
        link.current.download = filename
        link.current.href = href
        link.current.click()
    }
    return (
        <a
            role='button'
            ref={link}
            onClick={handleAction}
            {...otherProps}
        >
            {children}
        </a>
    )
}