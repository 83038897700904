//@flow
import React, {useCallback, useState} from 'react';
import {useUserData} from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import useUserAction from "../../../hooks/user/useUserAction";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import RangeSelect from "../../rangeSelect/rangeSelect";
import moment from "moment";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import Util from "../../util/util";
import Table from "../../table/table";
import './report5.css'
// import {withPermissionHOC} from "../../../modules/permission/components/permissionHOC/permissionHOC";
// import {Permissions} from "../../../helpers/constants";
import DropdownFilter from "../../table/partials/filters/dropdownFilter/dropdownFilter";
import TextFilter from "../../table/partials/filters/textFilter/textFilter";
import NumberRangeFilter from "../../table/partials/filters/numberRangeFilter/numberRangeFilter";
import OrderStatus from "../../orderStatus/orderStatus";
import {formatDate, formatTypes} from "../../../helpers/date";

const NumberCell = ({value}) => value && (
    <div style={{textAlign: "right"}}>
        {Number(value).toFixed(2)}
    </div>
)

const Report5 = () => {
    const {t} = useTranslation([PAGE_REPORTS])
    const {report5IndexTable} = useUserData()
    const {getReport5Info} = useUserAction()

    const [range, setRange] = useState([
        moment().subtract(6, 'months').toDate(), new Date()
    ])

    const onSubmit = (values) => {
        const {range} = values
        setRange(range)
    }

    const dataManager = useCallback((pageIndex, pageSize) => {
        getReport5Info(pageIndex, pageSize, range)
    }, [getReport5Info, range])

    return (
        <div className={'report1-page contained-page bg-container'}>
            <Util>
                <Title variant={"h3"}>
                    {t(PAGE_REPORTS + ':ORDERS PER ITEM')}
                </Title>
            </Util>
            <Divider/>
            <RangeSelect
                initialRange={range}
                onSubmit={onSubmit}
            />
            <br/>
            <div className="table-wrapper">
                <Table
                    data={report5IndexTable}
                    pageSize={10}
                    dataManager={dataManager}
                    noMeta
                    noPagination
                    customStyle
                    groupBy={['description']}
                    expanded={report5IndexTable
                        .items
                        .reduce((p, n) => ({
                            ...p,
                            ['description:' + n.description]: true
                        }), {})
                    }
                    columns={[
                        {
                            Header: t('Item'),
                            accessor: 'description',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Quantity'),
                            accessor: 'quantity',
                            aggregate: 'sum',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell,
                        },
                        {
                            Header: t('order'),
                            accessor: 'code',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('transferredDate'),
                            accessor: 'transferredDate',
                            Cell: ({value}) => value && (
                                <div>
                                    <strong>{formatDate(value, formatTypes.TITLE_HALF)} </strong>
                                    <span>({formatDate(value, formatTypes.TIME_ONLY)})</span>
                                </div>
                            )
                        },
                        {
                            Header: t('date'),
                            accessor: 'date',
                            Cell: ({value}) => value && (
                                <div>
                                    <strong>{formatDate(value, formatTypes.TITLE_HALF)} </strong>
                                    <span>({formatDate(value, formatTypes.TIME_ONLY)})</span>
                                </div>
                            )
                        },
                        {
                            Header: t('status'),
                            accessor: 'statusId',
                            Cell: ({value, row}) => {
                                return value !== null && (
                                    <div style={{textAlign: "center"}}>
                                        <div style={{textAlign: "center"}}>
                                            <OrderStatus status={value}/>
                                        </div>
                                    </div>
                                );
                            }
                        },
                    ]}
                />
            </div>
        </div>
    )
}

// export default withPermissionHOC(Report5, [Permissions.AccessSalesPerson])
export default Report5
