import type {Product, ProductCategory} from "./types";
import {parseMultiple, parseMultipleFromReference} from "../../helpers/parse";
import _ from "lodash";

export const sortProducts = _.memoize((items) => _.orderBy(items,
    ['category.weight', 'subCategory.weight', 'weight'],
    ['desc', 'desc', 'desc'])
)

export const parseCategoryIndexTable = (datum, productsById, language) => {
    if (!datum) return {
        count: 0, items: [], loading: false
    }
    return {
        count: datum.get('count'),
        items: sortProducts(parseMultipleFromReference(datum.get('items'), productsById, (i) => parseProduct(i, language, productsById))),
        loading: datum.get('loading'),
    }
}

export const parseProductCategory: ProductCategory = (datum, referencedProductsById, language) => {
    if (!datum) return
    return {
        id: datum.get('id'),
        title: datum.getIn(['title', language]),
        children: parseMultiple(datum.get('children'), (i) => parseProductCategory(i, referencedProductsById, language)),
        typeId: datum.get('typeId'),
        image: datum.get('image'),
        parent: datum.get('parent'),
        weight: datum.get('weight'),
        products: parseCategoryIndexTable(datum.get('products'), referencedProductsById, language)
    }
}

const parseProductImage: Product = (datum) => {
    if (!datum) return
    return {
        id: datum.get('id'),
        original: datum.get('original'),
        thumbnail: datum.get('thumbnail'),
        isMainImage: datum.get('isMainImage'),
    }
}

const parseScalingValues = (datum) => {
    if (!datum) return
    return {
        priceWithoutTax: datum.get('priceWithoutTax'),
        priceWithTax: datum.get('priceWithTax'),
        fromQuantity: datum.get('fromQuantity'),
    }
}

const parseLabel = (datum, language) => {
    if (!datum) return
    return {
        description: datum.getIn(['description', language]),
        id: datum.get('id'),
        backgroundColor: datum.get('backgroundColor'),
        color: datum.get('color'),
        weight: datum.get('weight'),

    }
}

export const parseProduct: Product = (datum, language, allById) => {
    if (!datum) return
    const id = datum.get('id')
    return {
        id: id,
        similar: parseMultiple(
            datum.get('similar'),
            (i) => parseProduct(
                allById.get(i),
                language,
                allById.filter((v, k) => k !== id))
        ),
        labels: parseMultiple(datum.get('labels'), (i) => parseLabel(i, language)),
        scalingValues: parseMultiple(datum.get('scalingValues'), parseScalingValues),
        isFavorite: datum.get('isFavorite'),
        discountWeb: datum.get('discountWeb'),
        label: datum.get('label'),
        message: datum.getIn(['message', language]),
        thumbnail: datum.get('thumbnail'),
        availabilityStatus: datum.get('availabilityStatus'),
        retailPrice: datum.get('retailPrice'),
        unitOfMeasurement: datum.getIn(['unitOfMeasurement', language]),
        basicUnitOfMeasurement: datum.getIn(['basicUnitOfMeasurement', language]),
        multiplier: datum.get('multiplier'),
        itemStock: datum.get('itemStock'),
        priceWithTax: datum.get('priceWithTax'),
        priceWithoutTax: datum.get('priceWithoutTax'),
        code: datum.get('code'),
        description: datum.getIn(['description', language]),
        quantity: datum.get('quantity'),
        step: datum.get('step'),
        exclusiveToOrderTypes: parseMultiple(datum.get('exclusiveToOrderTypes'), (i) => i),
        images: parseMultiple(datum.get('images'), parseProductImage),
        title: datum.getIn(['title', language]),
        invoicePolicy: datum.get('invoicePolicy') ? {
            appliesFromQuantity: datum.getIn(['invoicePolicy', 'appliesFromQuantity']),
            normalQuantity: datum.getIn(['invoicePolicy', 'normalQuantity']),
            discountQuantity: datum.getIn(['invoicePolicy', 'discountQuantity']),
            discountPercentage: datum.getIn(['invoicePolicy', 'discountPercentage']),
            history: parseMultiple(datum.getIn(['invoicePolicy', 'history']), (i) => parseProduct(i, language, allById))
        } : null,
        categoryId: datum.getIn(['category', 'id']),
        subCategoryId: datum.getIn(['subCategory', 'id']),
        weight: datum.get('weight'),
        popularWeight: datum.get('popularWeight'),
        category: {
            weight: datum.getIn(['category', 'weight']),
            id: datum.getIn(['category', 'id']),
            title: datum.getIn(['category', 'title', language]),
        },
        subCategory: {
            weight: datum.getIn(['subCategory', 'weight']),
            id: datum.getIn(['subCategory', 'id']),
            title: datum.getIn(['subCategory', 'title', language]),
        },
    }
}