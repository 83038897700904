//@flow
import React, {useCallback, useEffect, useState} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Button from "../../components/button/button";
import Icon from "../../components/icon/icon";
import Images from "../../assets/images";
import Divider from "../../components/divider/divider";
import List from "../../components/list/list";
import ListItem from "../../components/list/partials/listItem/listItem";
import ListItemSubtitle from "../../components/list/partials/listItemSubtitle/listItemSubtitle";
import ListItemTitle from "../../components/list/partials/listItemTitle/listItemTitle";
import ListItemText from "../../components/list/partials/listItemText/listItemText";
import './home.css'
import Banner from "../../components/banner/banner";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ANNOUNCEMENT, ROUTE_PAGE_ANNOUNCEMENTS, ROUTE_PAGE_ORDERS} from "../../routers/routes";
import useOrderAction from "../../hooks/order/useOrderAction";
import {useOrderData} from "../../hooks/order/useOrderData";
import {formatDate, formatTypes} from "../../helpers/date";
import useMessageAction from "../../hooks/message/useMessageAction";
import {AnnouncementTypes, MESSAGE_ACTION_TYPE_B2B, ReadStatus} from "../../helpers/constants";
import useMessageData from "../../hooks/message/useMessageData";
import {useTranslation} from "react-i18next";
import {PAGE_ANNOUNCEMENTS, PAGE_HOME} from "../../locales/pages/namespaces";
import CreateOrder from "../../components/createOrder/createOrder";
import OrderStatus from "../../components/orderStatus/orderStatus";
import useProductAction from "../../hooks/product/useProductAction";
import useProductData from "../../hooks/product/useProductData";
import ProductsList from "../../components/productsList/productsList";
import {useUserData} from "../../hooks/user/useUserData";
import UnstructuredTableView from "../../components/table/partials/views/unstructuredTableView";
import withInfiniteScroll from "../../components/table/withInfiniteScroll";

const ViewInfiniteScroll = withInfiniteScroll(UnstructuredTableView)

export const Tab = {
    popular: 0,
    new: 1,
    offers: 2,
    favorites: 3,
}

const Home = () => {
    const {t} = useTranslation(PAGE_HOME)
    const [productsTab, setProductsTab] = useState(Tab.offers)
    const {getPopularAndNewProducts} = useProductAction()
    const {banner} = useProductData()
    let bannerItems
    switch (productsTab) {
        case Tab.offers:
            bannerItems = banner.offers
            break
        case Tab.favorites:
            bannerItems = banner.favorites
            break
        case Tab.new:
            bannerItems = banner.new
            break
        default:
        case Tab.popular:
            bannerItems = banner.popular
            break
    }
    const {data} = useUserData()
    const {getOrders} = useOrderAction()
    const {indexTable: ordersIndexTable} = useOrderData()
    const {getMessages} = useMessageAction(MESSAGE_ACTION_TYPE_B2B, AnnouncementTypes.All, ReadStatus.Any)
    const {indexTable: announcementsIndexTable} = useMessageData()
    useEffect(() => {
        getOrders(0, 3)
    }, [getOrders])
    // useEffect(() => {
    //     getMessages(0, 10)
    // }, [getMessages])
    const dataManager = useCallback((pageIndex, pageSize) => {
        getPopularAndNewProducts(pageIndex, pageSize, productsTab)
    }, [getPopularAndNewProducts, productsTab])
    return (
        <div className={'home container'}>
            <div className="home__top">
                <div>
                    {/*<div>*/}
                    {/*    <Util>*/}
                    {/*        <Title invertSup variant={"h2"} sup={t('latest')}>*/}
                    {/*            {t('news')}*/}
                    {/*        </Title>*/}
                    {/*        <div className={'hide-mobile'}>*/}
                    {/*            <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS)}>*/}
                    {/*                {t('all')} {t('news')} <Icon icon={Images.icons.arrowRight}/>*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}
                    {/*    </Util>*/}
                    {/*    <Divider/>*/}
                    {/*    <div className={'show-mobile'} style={{textAlign: 'right'}}>*/}
                    {/*        <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS)}>*/}
                    {/*            {t('all')} {t('news')} <Icon icon={Images.icons.arrowRight}/>*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}
                    {/*    <List dense>*/}
                    {/*        {announcementsIndexTable.items.slice(0, 10).map(item => {*/}
                    {/*            const {typeId, date, title, id, text} = item*/}
                    {/*            return (*/}
                    {/*                <ListItem noBorders key={id}>*/}
                    {/*                    <Util>*/}
                    {/*                        <Link*/}
                    {/*                            // variant={'link'}*/}
                    {/*                            // component={Link}*/}
                    {/*                            style={{width: "79%"}}*/}
                    {/*                            to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENT, {id: id})}*/}
                    {/*                        >*/}
                    {/*                            <ListItemTitle variant={"h5"}>*/}
                    {/*                                {title}*/}
                    {/*                            </ListItemTitle>*/}
                    {/*                        </Link>*/}
                    {/*                        <ListItemSubtitle>*/}
                    {/*                            <div>*/}
                    {/*                                <ListItemSubtitle.Primary>*/}
                    {/*                                    {t(PAGE_ANNOUNCEMENTS + ':announcement_type_' + typeId)}*/}
                    {/*                                </ListItemSubtitle.Primary>*/}
                    {/*                                <span style={{marginRight: 8}}>&nbsp;</span>*/}
                    {/*                                <ListItemSubtitle.Secondary>*/}
                    {/*                                    {formatDate(date, formatTypes.DATE_ONLY_LONG).toUpperCase()}*/}
                    {/*                                </ListItemSubtitle.Secondary>*/}
                    {/*                            </div>*/}
                    {/*                        </ListItemSubtitle>*/}
                    {/*                    </Util>*/}
                    {/*                    /!*<ListItemText>*!/*/}
                    {/*                    /!*    <Dotdotdot clamp={2} useNativeClamp>*!/*/}
                    {/*                    /!*        <div dangerouslySetInnerHTML={{__html: text}}/>*!/*/}
                    {/*                    /!*    </Dotdotdot>*!/*/}
                    {/*                    /!*    <Button variant={'link'} component={Link}*!/*/}
                    {/*                    /!*            to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENT, {id: id})}>*!/*/}
                    {/*                    /!*        {t('read_more')} <Icon icon={Images.icons.arrowRight}/>*!/*/}
                    {/*                    /!*    </Button>*!/*/}
                    {/*                    /!*</ListItemText>*!/*/}
                    {/*                </ListItem>*/}
                    {/*            )*/}
                    {/*        })}*/}
                    {/*    </List>*/}
                    {/*    <div className="hide-mobile">*/}
                    {/*        <Divider/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div>
                        <Button.Group className={'wrap-when-more-than-2'}>
                            <CreateOrder/>
                        </Button.Group>
                        <div className="hide-mobile">
                            <Divider/>
                        </div>
                        <Util>
                            <Title invertSup variant={"h2"} sup={t('latest')}>
                                {t('orders')}
                            </Title>
                            <div className="hide-mobile">
                                <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                                    {t('all')} {t('orders')} <Icon icon={Images.icons.arrowRight}/>
                                </Button>
                            </div>
                        </Util>
                        <div className={'show-mobile'} style={{textAlign: 'right'}}>
                            <Divider/>
                            <Button variant={'link'} component={Link} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                                {t('all')} {t('orders')} <Icon icon={Images.icons.arrowRight}/>
                            </Button>
                        </div>
                        <List dense>
                            {ordersIndexTable.items.slice(0, 3).map(order => {
                                const {id, statusId, date, price} = order
                                return (
                                    <ListItem key={id}>
                                        <ListItemTitle variant={"body"}>
                                            {/*{store?.address?.toUpperCase()}*/}
                                            <OrderStatus status={statusId}/>
                                        </ListItemTitle>
                                        <ListItemText>
                                            <Util>
                                                <div>
                                                    <strong>{formatDate(date, formatTypes.TITLE_HALF)} </strong>
                                                    <span>({formatDate(date, formatTypes.TIME_ONLY).toUpperCase()})</span>
                                                </div>
                                                {/*<strong>{price}{data?.currencySymbol}</strong>*/}
                                            </Util>
                                        </ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Divider spacingBottom={1}/>
                        {/*<Banner/>*/}
                    </div>
                </div>
            </div>
            {/*<div className="home__middle">*/}
            {/*    <Util justifyContent={'initial'}>*/}
            {/*        <Title variant={"h3"} disabled={productsTab !== Tab.offers}*/}
            {/*               onClick={() => setProductsTab(Tab.offers)}>*/}
            {/*            {t('offers')}*/}
            {/*        </Title>*/}
            {/*        <Title variant={"h3"} sup={t('products')} disabled={productsTab !== Tab.popular}*/}
            {/*               onClick={() => setProductsTab(Tab.popular)}>*/}
            {/*            {t('popular')}*/}
            {/*        </Title>*/}
            {/*        <Title variant={"h3"} sup={t('products')} disabled={productsTab !== Tab.favorites}*/}
            {/*               onClick={() => setProductsTab(Tab.favorites)}>*/}
            {/*            {t('favorites')}*/}
            {/*        </Title>*/}
            {/*        <Title variant={"h3"} sup={t('products')} disabled={productsTab !== Tab.new}*/}
            {/*               onClick={() => setProductsTab(Tab.new)}>*/}
            {/*            {t('new')}*/}
            {/*        </Title>*/}
            {/*    </Util>*/}
            {/*    <br/>*/}
            {/*    <ProductsList*/}
            {/*        dataManager={dataManager}*/}
            {/*        data={{*/}
            {/*            loading: banner.loading,*/}
            {/*            items: bannerItems.slice(0, 10),*/}
            {/*            count: bannerItems.length*/}
            {/*        }}*/}
            {/*        noMeta*/}
            {/*        noPagination*/}
            {/*        displayAsTile={true}*/}
            {/*        view={ViewInfiniteScroll}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    )
}

export default Home;