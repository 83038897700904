import Home from "../pages/home/home";
import {routeCreator} from "../helpers/routeCreator";
import Announcements from "../pages/announcements/announcements";
import Login from "../pages/login/login";
import NotFound404 from "../pages/notFound404/notFound404";
import Orders from "../pages/orders/orders";
import Templates from "../pages/templates/templates";
import OnlinePayments from "../pages/onlinePayments/onlinePayments";
import OnlinePaymentsHistory from "../pages/onlinePaymentsHistory/onlinePaymentsHistory";
import SearchResults from "../pages/searchResults/searchResults";
import Order from "../pages/order/order";
import OrderEdit from "../pages/orderEdit/orderEdit";
import OrderPlaced from "../pages/orderPlaced/orderPlaced";
import ProductCategory from "../pages/productCategory/productCategory";
import Reports from "../pages/reports/reports";
import Terms from "../pages/terms/terms";
import Template from "../pages/template/template";
import CustomerTab from "../components/reports/customerTab4/customerTab";
import Report1 from "../components/reports/report1/report1";
import Report2 from "../components/reports/report2/report2";
import Report3 from "../components/reports/report3/report3";
import Report4 from "../components/reports/report4/report4";
import Report5 from "../components/reports/report5/report5";
import OnlinePaymentsFailure from "../pages/onlinePaymentsFailure/onlinePaymentsFailure";
import OnlinePaymentsSuccess from "../pages/onlinePaymentsSuccess/onlinePaymentsSuccess";
import OnlinePaymentsCancel from "../pages/onlinePaymentsCancel/onlinePaymentsCancel";
import AdminAddresses from "../pages/adminAddresses/adminAddresses";

export const ROUTE_PAGE_TERMS = routeCreator(Terms, '/terms', 'Terms', 'page.terms', {exact: true})
export const ROUTE_PAGE_HOME = routeCreator(Home, '/', 'Home', 'page.home', {exact: true})
export const ROUTE_PAGE_LOGIN = routeCreator(Login, '/login', 'login', 'Login', 'page.login', {exact: true})
export const ROUTE_PAGE_ANNOUNCEMENT = routeCreator(Announcements, '/announcements/:id', 'Announcements', 'page.announcements')
export const ROUTE_PAGE_ANNOUNCEMENTS = routeCreator(Announcements, '/announcements', 'Announcements', 'page.announcements.index', {exact: true})
export const ROUTE_PAGE_NOT_FOUND = routeCreator(NotFound404, '/', 'Not Found', 'page.404')
export const ROUTE_PAGE_ORDER_PLACED = routeCreator(OrderPlaced, '/orders/:id/placed', 'Order Placed', 'page.order.placed')
export const ROUTE_PAGE_ORDER_EDIT = routeCreator(OrderEdit, '/orders/:id/edit', 'Order Edit', 'page.order.edit')
export const ROUTE_PAGE_ORDER_FINALIZE = routeCreator(OrderEdit, '/orders/:id/edit/finalize', 'Order Finalize', 'page.order.finalize')
export const ROUTE_PAGE_ORDER = routeCreator(Order, '/orders/:id', 'Order', 'page.orders.single')
export const ROUTE_PAGE_ORDERS = routeCreator(Orders, '/orders', 'Orders', 'page.orders', {exact: true})
export const ROUTE_PAGE_ORDER_TEMPLATE = routeCreator(Template, '/templates/:id', 'Template', 'page.templates.single')
export const ROUTE_PAGE_ORDER_TEMPLATES = routeCreator(Templates, '/templates', 'Templates', 'page.templates', {exact: true})
export const ROUTE_PAGE_REPORTS_CUSTOMER_INFO = routeCreator(CustomerTab, '/reports/customer', 'Customer Tab', 'page.reports.customer.tab', {exact: true})
export const ROUTE_PAGE_REPORTS_1 = routeCreator(Report1, '/reports/report-1', 'Customer Report 1', 'page.reports.report.1', {exact: true})
export const ROUTE_PAGE_REPORTS_2 = routeCreator(Report2, '/reports/report-2', 'Customer Report 2', 'page.reports.report.2', {exact: true})
export const ROUTE_PAGE_REPORTS_3 = routeCreator(Report3, '/reports/report-3', 'Customer Report 3', 'page.reports.report.3', {exact: true})
export const ROUTE_PAGE_REPORTS_4 = routeCreator(Report4, '/reports/report-4', 'Customer Report 4', 'page.reports.report.4', {exact: true})
export const ROUTE_PAGE_REPORTS_5 = routeCreator(Report5, '/reports/report-items', 'Customer Report 5', 'page.reports.report.5', {exact: true})
export const ROUTE_PAGE_REPORTS = routeCreator(Reports, '/reports', 'Reports', 'page.reports', {exact: true})
export const ROUTE_PAGE_SEARCH_RESULTS = routeCreator(SearchResults, '/search-results', 'SearchResults', 'page.searchResults', {exact: true})
export const ROUTE_PAGE_PRODUCT_CATEGORY_PARENT = routeCreator(ProductCategory, '/product-category/:id', 'ProductCategory', 'page.product.category.only', {exact: true})
export const ROUTE_PAGE_PRODUCT_CATEGORY = routeCreator(ProductCategory, '/product-category/:id/subcategory/:subCategoryId', 'ProductCategory', 'page.productCategory')
export const ROUTE_PAGE_ONLINE_PAYMENTS = routeCreator(OnlinePayments, '/onlinePayments', 'OnlinePayments', 'page.onlinePayments', {exact: true})
export const ROUTE_PAGE_ONLINE_PAYMENTS_HISTORY = routeCreator(OnlinePaymentsHistory, '/onlinePayments/history', 'OnlinePayments', 'page.onlinePayments.history', {exact: true})
export const ROUTE_PAGE_ONLINE_PAYMENT_FAILURE = routeCreator(OnlinePaymentsFailure, '/onlinePayments/failure', 'OnlinePaymentsFailure', 'page.onlinePaymentsFailure')
export const ROUTE_PAGE_ONLINE_PAYMENT_SUCCESS = routeCreator(OnlinePaymentsSuccess, '/onlinePayments/success', 'OnlinePaymentsSuccess', 'page.onlinePaymentsSuccess')
export const ROUTE_PAGE_ONLINE_PAYMENT_CANCEL = routeCreator(OnlinePaymentsCancel, '/onlinePayments/cancel', 'OnlinePaymentsCancel', 'page.onlinePaymentsCancel')
export const ROUTE_PAGE_ADMIN_ADDRESSES = routeCreator(AdminAddresses, '/admin/addresses', 'Admin Addresses', 'page.admin.addresses', {exact: true})


const router = {
    ROUTE_PAGE_TERMS,
    // ROUTE_PAGE_REPORTS_CUSTOMER_INFO,
    // ROUTE_PAGE_REPORTS_1,
    // ROUTE_PAGE_REPORTS_2,
    // ROUTE_PAGE_REPORTS_3,
    // ROUTE_PAGE_REPORTS_4,
    ROUTE_PAGE_REPORTS_5,
    ROUTE_PAGE_REPORTS,
    ROUTE_PAGE_PRODUCT_CATEGORY_PARENT,
    ROUTE_PAGE_PRODUCT_CATEGORY,
    ROUTE_PAGE_SEARCH_RESULTS,
    // ROUTE_PAGE_ONLINE_PAYMENTS_HISTORY,
    // ROUTE_PAGE_ONLINE_PAYMENTS,
    // ROUTE_PAGE_ONLINE_PAYMENT_FAILURE,
    // ROUTE_PAGE_ONLINE_PAYMENT_SUCCESS,
    // ROUTE_PAGE_ONLINE_PAYMENT_CANCEL,
    ROUTE_PAGE_ORDER_PLACED,
    ROUTE_PAGE_ORDER_EDIT,
    ROUTE_PAGE_ORDER_FINALIZE,
    ROUTE_PAGE_ORDER,
    // ROUTE_PAGE_ORDER_TEMPLATE,
    // ROUTE_PAGE_ORDER_TEMPLATES,
    ROUTE_PAGE_ORDERS,
    ROUTE_PAGE_HOME,
    ROUTE_PAGE_LOGIN,
    // ROUTE_PAGE_ANNOUNCEMENTS,
    // ROUTE_PAGE_ANNOUNCEMENT,
    // ROUTE_PAGE_ADMIN_ADDRESSES,
    ROUTE_PAGE_NOT_FOUND,
}

export default router;