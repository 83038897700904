//@flow
import {useCallback, useContext} from 'react'
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import {useUserData} from "../user/useUserData";
import {MenuContext} from "../../pages/main/main";
import {Tab} from "../../pages/home/home";

const useProductAction = () => {
    const dispatch = useDispatch()
    const {selectedStore, data} = useUserData()
    const {menuCategoryType} = useContext(MenuContext)

    const getProductCategories = useCallback(() => {
        if (!menuCategoryType) return
        dispatch(actions.getProductCategories({
            AddressId: selectedStore.id,
            CustomerId: data?.id,
            OrderSheetId: menuCategoryType,
        }))
    }, [data?.id, dispatch, menuCategoryType, selectedStore.id])

    const getProducts = useCallback((pageIndex, pageSize) => {
        if (!menuCategoryType) return
        dispatch(actions.getProducts({
            AddressId: selectedStore.id,
            OrderSheetId: menuCategoryType,
            ItemCategoryId: 'all',
            requiresCounts: true,
            skip: pageIndex * pageSize,
            take: pageSize,
        }))
    }, [dispatch, menuCategoryType, selectedStore.id])

    const getPopularAndNewProducts = useCallback((categoryId, subCategoryId, productsTab) => {
        dispatch(actions.getPopularAndNewProducts({
            AddressId: selectedStore.id,
            OrderSheetCategoryId: menuCategoryType,
            ItemCategoryId: categoryId,
            ItemSubCategoryId: subCategoryId,
            getItems: productsTab === Tab.new ? 'new' :
                productsTab === Tab.popular ? 'popular' :
                    productsTab === Tab.favorites ? 'favorites' :
                        'offers'
        }))
    }, [dispatch, menuCategoryType, selectedStore.id])

    const getPopularPerCategory = useCallback(() => {
        dispatch(actions.getPopularPerCategory({
            AddressId: selectedStore.id,
        }))
    }, [dispatch, selectedStore.id])

    const getSearchResults = useCallback((pageIndex, pageSize, searchQuery) => {
        if (!searchQuery || searchQuery === '') return
        dispatch(actions.getSearchResults({
            AddressId: selectedStore.id,
            query: searchQuery,
            requiresCounts: true,
            skip: pageIndex * pageSize,
            take: pageSize
        }))
    }, [dispatch, selectedStore.id])

    const getProduct = useCallback((id) => {
        dispatch(actions.getProduct({
            ItemId: id,
            AddressId: selectedStore.id,
        }))
    }, [dispatch, selectedStore.id])

    const favoriteProduct = useCallback((id, isFavorite) => {
        dispatch(actions.favoriteProduct({
            ItemId: id,
            isFavorite: isFavorite
        }))
    }, [dispatch])

    return {
        getPopularPerCategory,
        favoriteProduct,
        getProductCategories,
        getProducts,
        getPopularAndNewProducts,
        getProduct,
        getSearchResults
    }
}

export default useProductAction