//@flow
import * as React from 'react';
import {Fragment, useCallback, useEffect, useState} from 'react';
import classNames from "classnames";
import './menu.css'
import Icon from "../icon/icon";
import Images from "../../assets/images";
import Button from "../button/button";
import Title from "../title/title";
import useProductAction from "../../hooks/product/useProductAction";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_PRODUCT_CATEGORY, ROUTE_PAGE_PRODUCT_CATEGORY_PARENT} from "../../routers/routes";
import {useTranslation} from "react-i18next";
import {CLONE_ORDER_COMPONENT} from "../../locales/components/namespaces";
import _ from "lodash";
import {useHistory} from "react-router";
import useProductCategoryDetailsData from "../../hooks/product/useProductCategoryDetailsData";
import MenuOld from "./old/menu.old"
import useProductData from "../../hooks/product/useProductData";
import Preview from "../preview/preview";
import useActiveOrderUpdate from "../../hooks/order/useActiveOrderUpdate";
import Divider from "../divider/divider";

type Props = {
    children: React.Node,
    onClose?: Function,
    show?: boolean,
    menuCategoryType: number,
}

const Menu = (props: Props) => {
    const {onProductQuantitySelect} = useActiveOrderUpdate()
    const {t} = useTranslation(CLONE_ORDER_COMPONENT)
    const {show, onClose, menuCategoryType} = props
    const [openSubmenu, setOpenSubmenu] = useState(null)
    const {getProductCategories, getPopularPerCategory} = useProductAction()
    const {all: productCategories, loading} = useProductCategoryDetailsData({typeId: menuCategoryType})
    const history = useHistory()
    const goToTop = useCallback(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])
    useEffect(() => {
        getProductCategories()
    }, [getProductCategories])

    useEffect(() => {
        if (!show) {
            setTimeout(() => setOpenSubmenu(null), 1000)
        }
    }, [show])

    const {banner} = useProductData()
    const bannerItems = banner.category

    // useEffect(() => {
    //     if (!(bannerItems && bannerItems.length > 0)){
    //         getPopularPerCategory()
    //     }
    // }, [bannerItems?.length, getPopularPerCategory])

    return (
        <div
            onMouseLeave={(e) => {
                const rect = e.target.getBoundingClientRect();
                if (rect.top > e.clientY) return
                onClose()
            }}
            // onClick={onClose}
            className={classNames('menu', {
                'menu--visible': show
            })}
        >
            <section
                className={'menu__content'}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            >
                {!Boolean(openSubmenu) && (
                    <div className={'menu__container menu__container--first'}>
                        <div>
                            <Title variant={"h4"} onClick={() => {
                                history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                    id: 'all',
                                }))
                                goToTop()
                                onClose()
                            }}>
                                {t('view_all_global')}
                            </Title>

                        </div>
                        <div className={'links'}>
                            {_.orderBy(productCategories.filter(i => !i.parent), 'weight', 'desc')
                                .map(i => (
                                    <button
                                        className={'menu__link--tile'}
                                        key={i.id}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (i.children && i.children.length > 0) {
                                                return setOpenSubmenu(i)
                                            }
                                            history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                                id: i.id,
                                            }))
                                            goToTop()
                                            onClose()
                                        }}
                                    >
                                        {i.image && (
                                            <img src={i.image}
                                                 className={'tile-background'}
                                                 alt={'tile background'}
                                            />
                                        )}
                                        <span>
                                            {i.title}
                                        </span>
                                    </button>
                                ))}
                            {loading && <div>{t('loading')}...</div>}
                        </div>
                        <Button variant={"icon"} onClick={onClose} className={'close-icon'}>
                            <Icon icon={Images.icons.close}/>
                        </Button>
                    </div>
                )}
                {Boolean(openSubmenu) && (
                    <div className={'menu__container menu__container--second'}>
                        <Title variant={"h4"} style={{maxWidth: 140}} onClick={() => {
                            history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                id: openSubmenu.id,
                            }))
                            goToTop()
                            onClose()
                        }}>
                            {t('view_all')} {openSubmenu.title}
                        </Title>
                        <div className="inner-menu-container">
                            <button
                                className={'menu__link--tile menu__link--tile-with-corner'}
                                onClick={(e) => {
                                    history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                        id: openSubmenu.id,
                                    }))
                                    goToTop()
                                    onClose()
                                }}
                            >
                                {/*<img src={Images.menu.corner_4} alt={'corner 1'}/>*/}
                                {/*<img src={Images.menu.corner_5} alt={'corner 2'}/>*/}
                                {openSubmenu.image && (
                                    <img src={openSubmenu.image}
                                         className={'tile-background'}
                                         alt={'tile background'}
                                    />
                                )}
                                <span>
                                    {openSubmenu.title}
                                </span>
                            </button>
                            <div className={'links'}>
                                {_.orderBy(openSubmenu.children, 'weight', 'desc')
                                    .map(i => (
                                        <Link
                                            onClick={onClose}
                                            className={'menu__link--default'}
                                            to={getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY, {
                                                id: openSubmenu.id,
                                                subCategoryId: i.id
                                            })}
                                            key={i.id}
                                        >
                                            {i.title}
                                        </Link>
                                    ))}
                            </div>
                            <div>
                                {bannerItems
                                    .filter(b => b.categoryId === openSubmenu.id)
                                    .slice(0, 2)
                                    .map((b, i, items) => (
                                        <Fragment key={b.id}>
                                            <Preview
                                                product={b}
                                                displayMini
                                                onAdd={onProductQuantitySelect}
                                                orderType={menuCategoryType}
                                            />
                                            {!(i + 1 === items.length) &&
                                                <Divider thin spacingTop={1} spacingBottom={1}/>}
                                        </Fragment>
                                    ))}
                            </div>
                        </div>
                        <Button variant={"icon"} onClick={() => setOpenSubmenu(null)} className={'close-icon'}>
                            <Icon icon={Images.icons.arrowLeft}/>
                        </Button>
                    </div>
                )}
            </section>
        </div>
    )
}

Menu.Mobile = MenuOld.Mobile

export default Menu;