// @flow
import React, {Fragment} from 'react';
import Button from "../../button/button";
import Util from "../../util/util";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import Preview from "../../preview/preview";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../../locales/pages/namespaces";
import Icon from "../../icon/icon";
import Images from "../../../assets/images";
import type {Order} from "../../../types/order/types";
import {calculateProductSum, calculateProductTax, categorizeProducts} from "../../../helpers/helpers";
import {formatDate, formatTypes} from "../../../helpers/date";
import OrderStatus from "../../orderStatus/orderStatus";
import BillingAndShippingInfo from "../partials/billingAndShippingInfo/billingAndShippingInfo";
import OrderType from "../../orderType/orderType";
import {useUserData} from "../../../hooks/user/useUserData";
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import {useHasPermission} from "../../../modules/permission/hooks/useHasPermission";
import {Permissions} from "../../../helpers/constants";
import RouteCalculator from "../../routeCalculator/routeCalculator";

type Props = {
    order: Order,
    onGoBack: Function,
    onSubmit: Function
};

const Overview = (props: Props) => {
    const {t} = useTranslation(PAGE_ORDERS)
    const {
        onGoBack,
        setNextRouteDate,
        nextRouteDate,
        order: {
            type,
            typeDescription,
            products,
            notes,
            billing,
            shipping,
            code,
            date,
            statusId
        }
    } = props
    const validationSchema = yup.object({
        // acceptBalance: yup.boolean().oneOf([true], t('accept_balance_must_be_checked'))
    })

    const formik = useFormik({
        initialValues: {
            // acceptBalance: props.order.acceptBalance
        },
        validationSchema: validationSchema,
        onSubmit: props.onSubmit
    })

    const {acceptBalance} = formik.values

    const {data} = useUserData()
    const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})
    const categorizedProducts = categorizeProducts(products)

    const sum = calculateProductSum(products).toFixed(2)
    const tax = calculateProductTax(products).toFixed(2)
    return (
        <div className={'bg-container contained-page order-create-page'}>
            <Button variant={'link'} onClick={onGoBack}>
                <Icon icon={Images.icons.arrowLeft}/> {t('back_to_edit')}
            </Button>
            <Title sup={t('finalize_2')}>
                {t('finalize_1')}
            </Title>
            <Divider/>
            <br/>
            <br/>
            <Util>
                <div>
                    {billing && shipping && (
                        <BillingAndShippingInfo
                            billing={billing}
                            shipping={shipping}
                        />
                    )}
                    <Divider thin/>
                </div>
                <div className={'info expand-mobile'}>
                    <Util align={'baseline'}>
                        <strong>{t('Order Number')}: </strong><span>#{code}</span>
                    </Util>
                    <Divider thin/>
                    <Util align={'baseline'}>
                        <strong>{t('Type')}: </strong>
                        <OrderType type={type} typeDescription={typeDescription}/>
                    </Util>
                    <Divider thin/>
                    <Util align={'baseline'}>
                        <strong>{t('Date Placed')}: </strong><span>{formatDate(date, formatTypes.DATE_ONLY)}</span>
                    </Util>
                    <Divider thin/>
                    <Util align={'baseline'}>
                        <strong>{t('Status')}: </strong><OrderStatus status={statusId}/>
                    </Util>
                    <Divider thin/>
                    <div className={'info'}>
                        <RouteCalculator
                            setNextRouteDate={setNextRouteDate}
                            nextRouteDate={nextRouteDate}
                        />
                        <Divider thin/>
                    </div>
                </div>
            </Util>
            <br/>
            {Object
                .keys(categorizedProducts)
                .map(category => {
                    const products = categorizedProducts[category];
                    return (
                        <Fragment key={category}>
                            <br/>
                            <Title style={{marginBottom: '.5rem'}} variant={"h4"}>
                                {category}
                            </Title>
                            {products.map((product) => (
                                <Preview
                                    key={product.id}
                                    product={product}
                                    disabled
                                    disabledFilled
                                    orderType={type}
                                />
                            ))}
                        </Fragment>
                    )
                })}
            <br/>
            <div className="order-create-page__bottom expand-mobile">
                <div className="order-create-page__bottom_left hide-mobile">
                    <div className={"order-create-page__notes"}>
                        <Title variant={"h4"}>
                            {t('notes')}
                        </Title>
                        <div dangerouslySetInnerHTML={{__html: notes}}/>
                    </div>
                </div>
                <div className="order-create-page__bottom_right expand-mobile">
                    {/*<Util>*/}
                    {/*    <Title variant={"h4"}>*/}
                    {/*        {t('total_cost')} {t('with_tax')}*/}
                    {/*    </Title>*/}
                    {/*    <Title variant={"h4"} subtitle={t('tax') + ' (' + tax + data?.currencySymbol + ')'}>*/}
                    {/*        {sum}{data?.currencySymbol}*/}
                    {/*    </Title>*/}
                    {/*</Util>*/}
                    <Divider spacingTop={1} spacingBottom={1}/>
                    <div className="order-create-page__bottom_left show-mobile">
                        <div className={"order-create-page__notes"}>
                            <Title variant={"h4"}>
                                {t('notes')}
                            </Title>
                            <div dangerouslySetInnerHTML={{__html: notes}}/>
                        </div>
                    </div>
                    {!isSalesPerson &&
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                {/*<div>*/}
                                {/*    <Util align={'baseline'}>*/}
                                {/*        <FormGroup>*/}
                                {/*            <Checkbox*/}
                                {/*                id={'acceptBalance'}*/}
                                {/*                name={'acceptBalance'}*/}
                                {/*                checked={acceptBalance}*/}
                                {/*                onChange={formik.handleChange}*/}
                                {/*                onBlur={formik.handleBlur}*/}
                                {/*                label={t('accept_account_balance')}*/}
                                {/*            />*/}
                                {/*            <Error>*/}
                                {/*                <ErrorMessage name="acceptBalance"/>*/}
                                {/*            </Error>*/}
                                {/*        </FormGroup>*/}
                                {/*    </Util>*/}
                                {/*</div>*/}
                                <Button.Group>
                                    <Button type={'submit'} variant={'action'} sup={t('send_2')}>
                                        {t('send_1')}
                                    </Button>
                                </Button.Group>
                            </form>
                        </FormikProvider>
                    }
                </div>
            </div>
        </div>
    )
}

export default Overview;