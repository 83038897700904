//@flow
import React, {Fragment, useContext, useState} from 'react';
import './header.css'
import Title from "../title/title";
import Images from "../../assets/images";
import Badge from "../badge/badge";
import Icon from "../icon/icon";
import Button from "../button/button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
    ROUTE_PAGE_ADMIN_ADDRESSES,
    ROUTE_PAGE_ANNOUNCEMENTS,
    ROUTE_PAGE_HOME,
    ROUTE_PAGE_ONLINE_PAYMENTS,
    ROUTE_PAGE_ORDERS,
    ROUTE_PAGE_REPORTS,
    ROUTE_PAGE_SEARCH_RESULTS
} from "../../routers/routes";
import SearchPopup from "../searchPopup/searchPopup";
import useUserAction from "../../hooks/user/useUserAction";
import {MenuContext} from "../../pages/main/main";
import SelectStore from "../selectStore/selectStore";
import {useHistory} from "react-router";
import LanguageSelect from "../languageSelect/languageSelect";
import {HEADER_COMPONENT} from "../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import Menu from "../menu/menu";
import useMenuCounts from "../../hooks/user/useMenuCounts";
import {useUserData} from "../../hooks/user/useUserData";
import PermissionHOC from "../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../helpers/constants";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";
import SelectedStoreAdmin from "../selectedStoreAdmin/selectedStoreAdmin";

const Header = () => {
    const {orders: ordersCount, messages: messagesCount} = useMenuCounts()
    const {menuCategoryType, openMenu, setOpenMenu} = useContext(MenuContext)
    const {t} = useTranslation(HEADER_COMPONENT)
    const menu = useContext(MenuContext)
    const [openSearch, setOpenSearch] = useState(false)
    const {userLogout} = useUserAction()
    const history = useHistory()
    const {data} = useUserData()
    const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})
    const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})
    const onLinkClick = () => {
        menu.setOpenMenu(false)
    }

    return (
        <Fragment>
            <div className={'header'}>
                <div className="header__left">
                    <div className={'header__info'}>
                        {data ? <span>{data.loginName}:</span> : <span>{t('loading')}</span>}
                        {window.location &&
                            window.location.pathname &&
                            !window.location.pathname.startsWith(getRouteUrl(ROUTE_PAGE_REPORTS)) && !isAdmin && !isSalesPerson && (
                                <SelectStore/>
                            )}
                        {window.location &&
                            window.location.pathname &&
                            (isAdmin || isSalesPerson) && (<SelectedStoreAdmin/>)}
                    </div>
                </div>
                <div className="header__middle">

                    <button onMouseEnter={() => menu.setOpenMenu(true)} onClick={() => menu.setOpenMenu(true)}>
                        <span>{t('products')}</span>
                    </button>
                    <Link onClick={onLinkClick} to={getRouteUrl(ROUTE_PAGE_HOME)}>
                        <img className={'header__logo'} src={Images.logo.white} alt={'logo'}/>
                    </Link>
                    <Link onClick={onLinkClick} to={getRouteUrl(ROUTE_PAGE_ORDERS)}>
                        <span>{t('orders')}</span>{ordersCount > 0 && <Badge badgeContent={ordersCount}/>}
                    </Link>
                    {/*<Link onClick={onLinkClick} to={getRouteUrl(ROUTE_PAGE_ANNOUNCEMENTS)}>*/}
                    {/*    <span>{t('news')}</span>{messagesCount > 0 && <Badge badgeContent={messagesCount}/>}*/}
                    {/*</Link>*/}
                    {/*<PermissionHOC*/}
                    {/*    requiredPermissions={[Permissions.AccessReports]}*/}
                    {/*    notAllowedComponent={() => <span/>}*/}
                    {/*>*/}
                        <Link onClick={onLinkClick} to={getRouteUrl(ROUTE_PAGE_REPORTS)}>
                            <span>{t('reports')}</span>
                        </Link>
                    {/*</PermissionHOC>*/}
                    {/*<PermissionHOC*/}
                    {/*    requiredPermissions={[Permissions.AccessAdmin, Permissions.AccessSalesPerson]}*/}
                    {/*    notAllowedComponent={() => <span/>}*/}
                    {/*>*/}
                    {/*    <Link onClick={onLinkClick} to={getRouteUrl(ROUTE_PAGE_ADMIN_ADDRESSES)}>*/}
                    {/*        <span>{t('admin_addresses')}</span>*/}
                    {/*    </Link>*/}
                    {/*</PermissionHOC>*/}
                </div>
                <div className="header__right">
                    <div>
                        <div className={'header__info'}>
                            <Button variant={"icon"} onClick={() => {
                                onLinkClick()
                                setOpenSearch(true);
                            }}>
                                <Icon icon={Images.icons.search}/>
                            </Button>
                            <LanguageSelect/>
                        </div>
                        <Button
                            component={Title}
                            variant={"link"}
                            onClick={userLogout}>
                            {t('logout')}
                        </Button>
                    </div>
                </div>
            </div>
            <Menu
                show={openMenu}
                menuCategoryType={menuCategoryType}
                onClose={() => setOpenMenu(false)}
            />
            <SearchPopup
                visible={openSearch}
                onSubmit={(query) => {
                    history.push(getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS, null, {query}))
                    setOpenSearch(false)
                }}
                onClose={() => setOpenSearch(false)}
            />
        </Fragment>
    )
}

const HeaderMobile = () => {
    const {menuCategoryType, openMenu, setOpenMenu} = useContext(MenuContext)
    const menu = useContext(MenuContext)
    const [openSearch, setOpenSearch] = useState(false)
    const history = useHistory()
    return (
        <Fragment>
            <div className={'header header--mobile'}>
                <div className="header__left">
                    <div>
                        <Button
                            variant={"icon"}
                            onClick={() => {
                                menu.setOpenMenu(true)
                            }}>
                            <Icon icon={Images.icons.burger}/>
                        </Button>
                        <Button variant={"icon"} onClick={() => setOpenSearch(true)}>
                            <Icon icon={Images.icons.search}/>
                        </Button>
                        <LanguageSelect/>
                    </div>
                </div>
                <div className="header__right">
                    <Link to={getRouteUrl(ROUTE_PAGE_HOME)}>
                        <img className={'header__logo'} src={Images.logo.white} alt={'logo'}/>
                    </Link>
                </div>
            </div>
            <Menu.Mobile
                show={openMenu}
                menuCategoryType={menuCategoryType}
                onClose={() => setOpenMenu(false)}
            />
            <SearchPopup
                visible={openSearch}
                onSubmit={(query) => {
                    history.push(getRouteUrl(ROUTE_PAGE_SEARCH_RESULTS, null, {query}))
                    setOpenSearch(false)
                }}
                onClose={() => setOpenSearch(false)}
            />
        </Fragment>
    )
}

Header.Mobile = HeaderMobile

export default Header;