import _ from "lodash";
import type {Product} from "../types/product/types";
import moment from "moment/moment";

export const getDiscountQuantity = (product, quantity) => {
    const {invoicePolicy} = product
    return invoicePolicy ?
        (invoicePolicy.discountQuantity * (quantity / invoicePolicy.normalQuantity)) :
        null;
}

export const getMatchingScalingValue = (scalingValues, quantity) => _.findLast(_.orderBy(scalingValues, 'fromQuantity', 'asc'), (v => quantity >= v.fromQuantity))

export const rowPriceInfoCost = (
    unitPrice,
    quantity,
    discount = null,
    scalingValues = null,
    scalingValuesUseTax: boolean = true,
    discountWeb = null,
    calculatePriceWithDiscount: boolean = true
) => {
    let price = unitPrice
    if (scalingValues && scalingValues.length > 0) {
        const matching = getMatchingScalingValue(scalingValues, quantity)
        if (matching) {
            price = matching[scalingValuesUseTax ? 'priceWithTax' : 'priceWithoutTax']
        }
    }
    let cost = price * quantity
    if (discount) {
        cost = applyPercentage(cost, discount, true)
    }
    if (discountWeb && calculatePriceWithDiscount) {
        cost = applyPercentage(cost, discountWeb, true)
    }
    return cost
}

export const rowPriceInfoPricePerUnit = (priceWithoutTax, quantity, discount = null, scalingValues = null,  discountWeb = null) => {
    let pricePerUnit = priceWithoutTax
    if (scalingValues && scalingValues.length > 0) {
        const matching = getMatchingScalingValue(scalingValues, quantity)
        if (matching) {
            pricePerUnit = matching.priceWithoutTax
        }
    }
    if (discount) {
        pricePerUnit = applyPercentage(pricePerUnit, discount, true).toFixed(2)
    }
    if (discountWeb) {
        pricePerUnit = applyPercentage(pricePerUnit, discountWeb, true).toFixed(2)
    }
    return pricePerUnit
}


export const applyPercentage = (number: number, percentage: number, minusPercentage: boolean = false) => {
    if (minusPercentage) {
        return number * (1 - percentage / 100)
    }
    return number * (percentage / 100 + 1)
}

export const calculateProductSum = (products: Array<Product>, calculatePriceWithTax: boolean = true, calculatePriceWithDiscount: boolean = true) => {
    if (!(products && products.length > 0)) return 0
    return products.reduce((p, n) => {
        const {priceWithTax, priceWithoutTax, quantity, invoicePolicy, scalingValues, discountWeb} = n
        const unitPrice = calculatePriceWithTax ? Number(priceWithTax) : Number(priceWithoutTax)
        let sum = p + rowPriceInfoCost(
            unitPrice,
            quantity,
            null,
            scalingValues,
            calculatePriceWithTax,
            discountWeb,
            calculatePriceWithDiscount
        )
        if (invoicePolicy) {
            const discountQuantity = getDiscountQuantity(n, quantity)
            sum += rowPriceInfoCost(
                unitPrice,
                discountQuantity,
                invoicePolicy.discountPercentage,
                scalingValues,
                calculatePriceWithTax,
                discountWeb,
                calculatePriceWithDiscount
            )
        }
        return sum
    }, 0)
}

export const calculateProductTax = (products) => {
    const sumWithTax = calculateProductSum(products)
    const sumWithoutTax = calculateProductSum(products, false)
    return sumWithTax - sumWithoutTax
}

export const calculateProductDiscount = (products) => {
    const sumWithDiscount = calculateProductSum(products, false)
    const sumWithoutDiscount = calculateProductSum(products, false, false)
    return sumWithDiscount - sumWithoutDiscount
}

export const categorizeProducts = (products) => _.groupBy(products, (i) => i.category.title);
export const categorizeProductsBy = (products, by: string) => _.groupBy(products, (i) => i[by]);

export const updateProductQuantity = (products, product, quantity) => {
    if (quantity === 0) {
        return products.filter(p => p.id !== product.id)
    }
    const hasProduct = products.find(p => p.id === product.id)
    const newItem = {
        ...product,
        quantity: quantity
    }
    if (hasProduct) {
        return products.map((p) => p.id !== product.id ? p : newItem)
    }
    return [
        ...products,
        newItem
    ]
}

export function normalizeGreek(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

export const calculateNextRouteDate = (starDate, frequency) => {
    const today = moment();
    const daysSinceStart = today.diff(starDate, 'days');
    const daysUntilNextRoute = frequency - (daysSinceStart % frequency);
    const nextRouteDate = today.add(daysUntilNextRoute, 'days');

    return nextRouteDate
};
