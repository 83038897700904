import * as Namespaces from './namespaces'
import {AnnouncementTypes} from "../../helpers/constants";

const locales = {
    [Namespaces.PAGE_LOGIN]: {
        el: {
            'all': 'ΟΛΑ ΤΑ ΚΑΤΑΣΤΗΜΑΤΑ',
            'OR VISIT': 'Ή ΕΠΙΣΚΕΦΤΕΙΤΕ',
            wholesale_2: 'ΠΕΛΑΤΗΣ',
            wholesale_1: 'B2B',
            retail_2: 'ΠΕΛΑΤΗΣ',
            retail_1: 'ΛΙΑΝΙΚΗΣ',
            company_website: 'ΙΣΤΟΣΕΛΙΔΑ ΕΤΑΙΡΕΙΑΣ',
            customer: 'ΠΕΛΑΤΗΣ',
            required: 'Συμπληρώστε το πεδίο',
            User: 'Χρήστης',
            Password: 'Κωδικός',
            login: 'ΣΥΝΔΕΣΗ',
            reset: 'ΕΠΑΝΑΦΟΡΑ',
            CANCEL: 'ΑΚΥΡΩΣΗ',
            loading: 'Φόρτωση',
            select_store: 'Επιλέξτε το Κατάστημά σας',
            'Forgot your password?': 'Ξεχάσατε τον κωδικό;',
            'reset_password_message': 'Συμπληρώστε το όνομα χρήστη σας και ακολουθήστε τις οδηγίες.',
            'Press here': 'Πατήστε εδώ',
            'Network Error': 'Σφάλμα δικτύου.',
            'Unauthenticated.': 'Χωρίς εξουσιοδότηση.',
            'cannot_get_addresses': 'Δεν μπορεί να γίνει λήψη των διευθύνσεων. Παρακαλούμε δοκιμάστε αργότερα.',
            'YOU cannot ACCESS THIS PAGE.': 'ΔΕΝ έχετε ΔΙΚΑΙΩΜΑ ΠΡΟΣΒΑΣΗΣ στην ΣΕΛΙΔΑ',
            'PAGE NOT FOUND': 'Η ΣΕΛΙΔΑ ΔΕΝ ΒΡΕΘΗΚΕ',
            'Six digit code': 'Εξαψήφιος κωδικός',
            'Confirm Password': 'Κωδικός επαλήθευσης',
            'Password must be between 7-16 latin characters and contain at least 3 of the following. -Upper case character -Lower case character -Numeric digit -Special character': 'Ο κωδικός πρόσβασης πρέπει να αποτελείται από 7-16 λατινικούς χαρακτήρες και να περιέχει τουλάχιστον 3 από τα ακόλουθα. -Κεφάλαιο -Πεζό χαρακτήρα -Αριθμητικό ψηφίο -Ειδικός χαρακτήρας',
            'Passwords must match': 'Οι κωδικοί πρέπει να ταιριάζουν',
            'Wrong 6 digit password!': 'Ο εξαψήφιος κωδικός είναι λάθος ή έχει λήξει.',
            'Not Found!': 'Ο χρήστης δεν βρέθηκε!'
        },
        en: {
            'all': 'ALL STORES',
            reset: 'RESET',
            'reset_password_message': 'Enter your username and follow the instructions.',
            wholesale_1: 'B2B',
            wholesale_2: 'WHOLESALE',
            retail_1: 'CUSTOMER',
            retail_2: 'RETAIL',
            company_website: 'COMPANY WEBSITE',
            customer: 'CUSTOMER',
            required: 'Required field',
            login: 'LOGIN',
            loading: 'Loading',
            select_store: 'Select your store',
            'cannot_get_addresses': 'We cannot retrieve your store information. Try again later.',
        }
    },
    [Namespaces.PAGE_ORDERS]: {
        el: {
            at_least_one: 'Παρακαλούμε προσθέστε τουλάχιστον 1 προϊόν στην παραγγελία σας.',
            order_status_0: 'ΜΕΤΑΒΟΛΗ',
            order_status_1: 'ΑΠΕΣΤΑΛΜΕΝΗ',
            order_status_2: 'ΣΕ ΕΠΕΞΕΡΓΑΣΙΑ',
            order_status_4: 'ΠΡΟΣ ΠΑΡΑΔΟΣΗ',
            order_status_finalize: 'ΥΠΟΒΟΛΗ',
            order_type_1: 'Παραγγελία Προϊόντων',
            order_type_4: 'Επιστροφή ειδών εγγυοδοσίας',
            order_type_3: 'Εβδομαδιαία Παραγγελία',
            thanks: 'ΕΥΧΑΡΙΣΤΟΥΜΕ ΓΙΑ ΤΗΝ <br/> ΠΑΡΑΓΓΕΛΙΑ ΣΑΣ!',
            Products: 'Προϊόντα',
            create_template: 'ΔΗΜΙΟΥΡΓΙΑ TEMPLATE',
            repeat_order: 'ΕΠΑΝΑΛΗΨΗ ΠΑΡΑΓΓΕΛΙΑΣ',
            'Date Placed': 'Ημερομηνία',
            'Status': 'Κατάσταση',
            'Type': 'Τύπος',
            'Estimated delivery': 'Εκτιμώμενη παράδοση',
            back: 'ΕΠΙΣΤΡΟΦΗ',
            back_to_edit: 'ΠΙΣΩ ΣΤΗΝ ΕΠΕΞΕΡΓΑΣΙΑ',
            back_to_home: 'ΠΙΣΩ ΣΤΗΝ ΑΡΧΙΚΗ',
            back_to_orders: 'ΠΙΣΩ ΣΤΙΣ ΠΑΡΑΓΓΕΛΙΕΣ',
            notes: 'ΣΗΜΕΙΩΣΕΙΣ',
            finalize_1: 'ΑΠΟΘΗΚΕΥΣΗ',
            finalize_2: 'ΠΑΡΑΓΓΕΛΙΑΣ',
            view_1: 'ΕΠΙΣΚΟΠΗΣΗ',
            view_2: 'ΠΑΡΑΓΓΕΛΙΩΝ',
            send_1: 'ΑΠΟΣΤΟΛΗ',
            send_2: 'ΠΑΡΑΓΓΕΛΙΑΣ',
            write_your_message: 'Γράψτε το μήνυμα σας εδώ',
            please_accept_terms: 'Παρακαλούμε αποδεχτείτε τους όρους χρήσης',
            terms: 'ΟΡΟΙ ΧΡΗΣΗΣ',
            accept_terms: 'Αποδέχομαι τους όρους χρήσης',
            total_price: 'ΣΥΝΟΛΙΚΗ ΑΞΙΑ <br/>ΑΓΟΡΩΝ',
            total_credits: 'ΣΥΝΟΛΙΚΗ ΑΞΙΑ <br/>ΥΠΟΛΟΙΠΟΥ',
            last_3_months: 'ΤΡΕΧΟΝΤΟΣ ΕΤΟΥΣ',
            pay_online: 'ONLINE ΠΛΗΡΩΜΗ',
            templates_1: 'TEMPLATES',
            templates_2: 'ΠΑΡΑΓΓΕΛΙΩΝ',
            show: 'ΠΡΟΒΟΛΗ',
            with_tax: 'με φπα',
            tax: 'ΦΠΑ',
            discount: 'ΕΚΠΤΩΣΗ',
            print: 'ΕΚΤΥΠΩΣΗ',
            total_cost: 'ΣΥΝΟΛΙΚΗ ΑΞΙΑ',
            change: 'ΜΕΤΑΒΟΛΗ',
            delete: 'ΔΙΑΓΡΑΦΗ',
            cancel: 'ΑΚΥΡΟ',
            'Order Number': 'Κωδικός Παραγγελίας',
            delete_warning: 'Η τρέχουσα παραγγελία πρόκειται να διαγραφεί.<br/>\n' +
                'Η ενέργεια αυτή είναι μη αναστρέψιμη. <br/>\n' +
                'Παρακαλούμε επιβεβαιώστε την διαγραφή της παραγγελίας.<br/>',
            delete_warning_template: 'Το template πρόκειται να διαγραφεί.<br/>\n' +
                'Η ενέργεια αυτή είναι μη αναστρέψιμη. <br/>\n' +
                'Παρακαλούμε επιβεβαιώστε την διαγραφή.<br/>',
            order_submitted_title: 'ΑΠΕΣΤΑΛΜΕΝΗ παραγγελια',
            order_submitted_message: 'Η παραγγελία έχει αποσταλεί και ως εκ τούτου δεν μπορείτε να την επεξεργαστείτε.',
            accept_account_balance: 'Έλαβα γνώση του σημερινού υπολοίπου της καρτέλας μου',
            accept_balance_must_be_checked: 'Πρέπει να αποδεχτείτε τους όρους της παραγγελίας για να συνεχίσετε'
        },
        en: {
            at_least_one: 'Please add at least one product to your order.',
            terms: 'TERMS OF USE',
            order_status_0: 'CHANGE',
            order_status_1: 'SENT',
            order_status_2: 'IN PROGRESS',
            order_status_4: 'FOR DELIVERY',
            order_status_finalize: 'SUBMIT',
            order_type_1: 'Παραγγελία Προϊόντων',
            order_type_4: 'Επιστροφή ειδών εγγυοδοσίας',
            order_type_3: 'Weekly Order',
            print: 'PRINT',
            back: 'BACK',
            back_to_home: 'BACK TO HOME',
            back_to_orders: 'BACK TO ORDERS',
            create_template: 'CREATE TEMPLATE',
            repeat_order: 'REPEAT ORDER',
            back_to_edit: 'BACK TO EDIT',
            with_tax: 'with tax',
            total_cost: 'TOTAL COST',
            tax: 'ΤΑΧ',
            discount: 'DISCOUNT',
            notes: 'NOTES',
            finalize_1: 'SAVE',
            finalize_2: 'ORDER',
            view_1: 'ORDERS',
            view_2: 'OVERVIEW',
            send_1: 'FINALIZE',
            send_2: 'ORDER',
            of_orders: 'ORDERS',
            write_your_message: 'Write your message here',
            please_accept_terms: 'Please accept terms and conditions',
            accept_terms: 'I agree to the terms and conditions',
            pay_online: 'PAY ONLINE',
            templates_1: 'ORDER',
            templates_2: 'TEMPLATES',
            show: 'SHOW',
            change: 'CHANGE',
            delete: 'DELETE',
            cancel: 'CANCEL',
            order_submitted_title: 'ORDER sent',
            order_submitted_message: 'This order has been sent, so you cannot edit it.',
            accept_account_balance: 'I was informed of my current account balance',
            accept_balance_must_be_checked: 'Please confirm order terms to submit order'
        }
    },
    [Namespaces.PAGE_HOME]: {
        el: {
            latest: 'ΤΕΛΕΥΤΑΙΕΣ',
            news: 'ΕΝΗΜΕΡΩΣΕΙΣ',
            orders: 'ΠΑΡΑΓΓΕΛΙΕΣ',
            products: 'ΠΡΟΙΟΝΤΑ',
            all: 'ΟΛΕΣ ΟΙ',
            read_more: 'ΔΙΑΒΑΣΕ ΠΕΡΙΣΣΟΤΕΡA',
            new: 'ΝΕΑ',
            popular: 'ΔΗΜΟΦΙΛΗ',
            offers: 'ΠΡΟΣΦΟΡΕΣ',
            favorites: 'ΑΓΑΠΗΜΕΝΑ',
        },
        en: {
            offers: 'OFFERS',
            favorites: 'FAVORITES',
            latest: 'LATEST',
            orders: 'ORDERS',
            products: 'PRODUCTS',
            news: 'NEWS',
            new: 'NEW',
            all: 'ALL',
            read_more: 'READ MORE',
            popular: 'POPULAR'
        }
    },
    [Namespaces.PAGE_ANNOUNCEMENTS]: {
        el: {
            announcements: 'ΕΝΗΜΕΡΩΣΕΙΣ',
            ['announcement_type_' + AnnouncementTypes.All]: 'ΟΛΕΣ ΟΙ ΕΝΗΜΕΡΩΣΕΙΣ',
            ['announcement_type_' + AnnouncementTypes.Eshop]: 'ESHOP',
            ['announcement_type_' + AnnouncementTypes.Company]: 'ΕΤΑΙΡΙΚΑ ΝΕΑ',
            ['announcement_type_' + AnnouncementTypes.Newsletter]: 'NEWSLETTER',
            read: 'ΔΙΑΒΑΣΜΕΝΑ',
            unread: 'ΑΔΙΑΒΑΣΤΑ',
            attachments: 'συνημμένα',
        },
        en: {
            announcements: 'ANNOUNCEMENTS',
            ['announcement_type_' + AnnouncementTypes.All]: 'ALL ANNOUNCEMENTS',
            ['announcement_type_' + AnnouncementTypes.Eshop]: 'ESHOP',
            ['announcement_type_' + AnnouncementTypes.Company]: 'COMPANY NEWS',
            ['announcement_type_' + AnnouncementTypes.Newsletter]: 'NEWSLETTER',
            read: 'READ',
            unread: 'UNREAD',
        }
    },
    [Namespaces.PAGE_REPORTS]: {
        el: {
            'ALL': 'ΟΛΟΙ',
            'Sales Person': 'Πωλητής',
            'NO CATEGORY': 'ΧΩΡΙΣ ΚΑΤΗΓΟΡΙΑ',
            '1st Period': '1η Περίοδος',
            '2nd Period': '2η Περίοδος',
            title_1: 'ΑΝΑΦΟΡΕΣ',
            title_2: 'ΑΓΟΡΩΝ',
            'PERIOD SALES PER ADDRESS AND PRODUCT': 'ΑΓΟΡΕΣ ΠΕΡΙΟΔΟΥ ΑΝΑ ΔΙΕΥΘΥΝΣΗ ΚΑΙ ΕΙΔΟΣ',
            'PERIOD SALES PER ADDRESS, PRODUCTS AND DATE': 'ΑΓΟΡΕΣ ΠΕΡΙΟΔΟΥ ΑΝΑ ΔΙΕΥΘΥΝΣΗ, ΕΙΔΟΣ ΚΑΙ ΗΜΕΡΟΜΗΝΙΑ',
            'COMPARATIVE DATA BETWEEN 2 PERIODS': 'ΣΥΓΚΡΙΤΙΚΑ ΣΤΟΙΧΕΙΑ ΔΥΟ ΠΕΡΙΟΔΩΝ',
            'CUSTOMER CARD': 'ΚΑΡΤΕΛΑ ΠΕΛΑΤΗ',
            'SALES PERSON ORDERS': 'ΠΑΡΑΓΓΕΛΙΕΣ ΑΠΟ ΠΩΛΗΤΕΣ',
            your_purchases: 'οι ΑΓΟΡΕΣ σας',
            last_12_months: 'ΤΟΥΣ ΤΕΛΕΥΤΑΙΟΥΣ 12 ΜΗΝΕΣ',
            last_month: ' ΤΟΝ ΤΕΛΕΥΤΑΙΟ ΜΗΝΑ',
            top_purchases: 'οι TOP αγορες σου',
            top_10_products: 'τα TOP 10 προϊοντα',
            Price: 'Τιμή',
            Quantity: 'Ποσότητα',
            comparable_1: 'συγκριτικα ΣΤΟΙΧΕΙΑ',
            comparable_2: 'ΔΥΟ ΤΕΛΕΥΤΑΙΩΝ 3ΜΗΝΩΝ',
            order: 'Παραγγελία',
            date: 'Ημερομηνία Δημιουργίας',
            orderSheet: 'Παραγγελιόχαρτο',
            'Address Erp Code': 'Κωδικός ERP',
            customer: 'Πελάτης',
            address: 'Διεύθυνση',
            'Country Code': 'Χώρα',
            'ciIdCode': 'Κωδικός CI',
            price: 'Τιμή',
            status: 'Κατάσταση',
            transferredDate: "Ημερομηνία Μεταφοράς",
            "ORDERS PER ITEM": "ΠΑΡΑΓΓΕΛΙΕΣ ΑΝΑ ΕΙΔΟΣ"
        },
        en: {
            your_purchases: 'your PURCHASES',
            last_12_months: 'LAST 12 MONTHS',
            top_purchases: 'your TOP purchases',
            top_10_products: 'TOP 10 products',
            last_month: 'LAST MONTH',
            title_1: 'PURCHASE',
            title_2: 'REPORTS',
            comparable_1: 'comparative data',
            comparable_2: 'LAST TWO QUARTERS',
            order: 'Order',
            date: 'Date',
            orderSheet: 'Ordersheet',
            'Address Erp Code': 'ERP Code',
            customer: 'Customer',
            address: 'Address',
            'Country Code': 'Country',
            'ciIdCode': 'CI Code',
            price: 'Price',
            status: 'Status',
            transferredDate: "Transfer Date"
        }
    },
    [Namespaces.PAGE_PAYMENTS]: {
        el: {
            title: 'ONLINE πληρωμες',
            message: '',
            remainder: 'ΣΥΝΟΛΙΚΗ ΑΞΙΑ ΥΠΟΛΟΙΠΟΥ',
            pay: 'ONLINE ΠΛΗΡΩΜΗ',
            last_payment: 'ΤΕΛΕΥΤΑΙΑ ONLINE ΠΛΗΡΩΜΗ',
            payment_history: 'ΙΣΤΟΡΙΚΟ ONLINE ΠΛΗΡΩΜΩΝ',
            payment_history_title: 'ΙΣΤΟΡΙΚΟ online πληρωμών',
            payment: 'ΠΛΗΡΩΜΗ',
            card: 'ΚΑΡΤΑ',
            back: 'ΠΙΣΩ',
            period_1: '1η ΠΕΡΙΟΔΟΣ',
            period_2: '2η ΠΕΡΙΟΔΟΣ',
            search_title: 'ΕΥΡΟΣ<br/>ΑΝΑΖΗΤΗΣΗΣ',
            required: 'Το πεδίο είναι απαραίτητο',
            'amount must be equal or less than remainder': 'Εισάγετε ποσό μικρότερο ή ίσο του υπολοίπου σας',
            'amount must be positive number': 'Το ποσό πρέπει να είναι θετικός αριθμός.'
        },
        en: {
            period_1: '1st PERIOD',
            period_2: '2nd PERIOD',
            title: 'ONLINE payments',
            message: '',
            remainder: 'TOTAL CREDITS VALUE',
            pay: 'PAY ONLINE',
            last_payment: 'LATEST ONLINE PAYMENT',
            payment_history: 'ONLINE PAYMENT HISTORY',
            payment_history_title: 'ONLINE payment history',
            payment: 'PAYMENT',
            card: 'CARD',
            back: 'BACK',
            required: 'Field is required',
            'amount must be equal or less than remainder': 'Amount must be less or equal to your remainder',
            'amount must be positive number': 'Amount must be a positive number'
        }
    },
    [Namespaces.PAGE_ADMIN_ADDRESSES]: {
        el: {
            title_admin: 'ΔΙΕΥΘΥΝΣΕΙΣ ΔΙΑΧΕΙΡΙΣΤΗ',
            title_salesPerson: 'ΔΙΕΥΘΥΝΣΕΙΣ ΠΩΛΗΤΗ',
            search_address: 'Αναζήτηση Διεύθυνσης'
        },
        en: {
            title_admin: 'ADMIN ADDRESSES',
            title_salesPerson: 'SALES PERSON ADDRESSES',
            search_address: 'Search Address'
        }
    }
}

export default locales