// @flow
import React, {useContext} from 'react';
import './activeOrder.css'
import {useOrderData} from "../../hooks/order/useOrderData";
import Util from "../util/util";
import {calculateProductDiscount, calculateProductSum} from "../../helpers/helpers";
import Title from "../title/title";
import Button from "../button/button";
import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {ROUTE_PAGE_ORDER_EDIT} from "../../routers/routes";
import {useTranslation} from "react-i18next";
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import {MenuContext} from "../../pages/main/main";
import Divider from "../divider/divider";
import {useUserData} from "../../hooks/user/useUserData";

const ActiveOrder = () => {
    const {menuCategoryType: orderType} = useContext(MenuContext)
    const {t} = useTranslation(PRODUCT_COMPONENT)
    const {activeOrder} = useOrderData()
    const activeOrderOfType = activeOrder.find(o => o.type === orderType)
    const {data} = useUserData()

    if (!activeOrderOfType) return null;
    const {products, typeDescription} = activeOrderOfType
    const sum = calculateProductSum(products).toFixed(2)
    const discount = calculateProductDiscount(products).toFixed(2)
    const subText = products.length === 1 ? t('product') : t('products')
    return (
        <div className={'active-order'}>
            <div>
                <div className={'active-order__item'}>
                    <Title variant={'body'} defaultFont>
                        {t('order')}
                    </Title>
                    <div>
                        {products.length + ' ' + subText}
                    </div>
                </div>
                {/*<div className="active-order__separator"/>*/}
                {/*<div className={'active-order__item'}>*/}
                {/*    <Util>*/}
                {/*        <Title variant={"body"} style={{ fontFamily: 'DR', color: '#26BC7D', paddingBottom: '4px' }}>*/}
                {/*            {t('discount').toUpperCase()}*/}
                {/*        </Title>*/}
                {/*        <Title variant={"body"} style={{ fontFamily: 'DR', color: '#26BC7D', paddingBottom: '4px' }}>*/}
                {/*            {discount}{data?.currencySymbol}*/}
                {/*        </Title>*/}
                {/*    </Util>*/}
                {/*    <Util>*/}
                {/*        <Title variant={'body'} defaultFont>*/}
                {/*            {t('total_cost')}*/}
                {/*        </Title>*/}
                {/*        <Title variant={'h4'}>*/}
                {/*            {sum}{data?.currencySymbol}*/}
                {/*        </Title>*/}
                {/*    </Util>*/}
                {/*    <div>*/}
                {/*        ({t('with_tax').toUpperCase()})*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="active-order__separator hide-mobile"/>*/}
                <div className={'active-order__item'}>
                    <Button.Group expandedButtons>
                        <Button
                            style={{justifyContent: "center"}}
                            variant={'action'}
                            component={Link}
                            to={getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {id: activeOrderOfType.id})}
                        >
                            {t('view')}
                        </Button>
                    </Button.Group>
                </div>
            </div>
            <Divider thin />
            <div style={{textAlign: "center"}}>
                {typeDescription}
            </div>
        </div>
    )
}

export default ActiveOrder;
