// @flow
import React, {useCallback, useEffect, useState} from 'react';
import TextField from "../form/elements/textfield/textField";
import Button from "../button/button";
import Icon from "../icon/icon";
import Images from "../../assets/images";
import {useTranslation} from "react-i18next";
import {SEARCH_COMPONENT} from "../../locales/components/namespaces";
import "./searchInput.css"
import classNames from "classnames";
import {useDebounce} from "use-debounce";

type Props = {
    placeholder?: string,
    query: string,
    onSubmit?: Function,
    onChange: Function,
    filled?: boolean
}

const SearchInput = (props: Props) => {
    const {t} = useTranslation(SEARCH_COMPONENT)
    const {filled, onSubmit, onChange, placeholder} = props;
    const [query, setQuery] = useState(props.query)
    const [queryChangedByUser, setQueryChangedByUser] = useState(false)
    const [debouncedQuery] = useDebounce(query, 1000)
    const onKeyUp = useCallback((e) => {
        if (onSubmit && (e.key === 'Enter' || e.keyCode === 13)) {
            onSubmit(query)
        }
    }, [onSubmit, query])
    useEffect(() => {
        // debugger
        setQuery(props.query)
    }, [props.query])
    useEffect(() => {
        if (queryChangedByUser) {
            onChange(debouncedQuery)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedQuery])
    return (
        <div className={classNames('search-input', {
            'search-input--filled': filled
        })}>
            <TextField
                autoFocus
                value={query}
                placeholder={placeholder ? placeholder : t('search')}
                onChange={(e) => {
                    setQuery(e.target.value)
                    setQueryChangedByUser(true)
                }}
                onKeyUp={onKeyUp}
            />
            <Button variant={"icon"} onClick={() => onSubmit && onSubmit(query)}>
                <Icon icon={Images.icons.search}/>
            </Button>
        </div>
    )
}

export default SearchInput;