import type {
    Report3Info, Report4Info, Report5Info,
    Store,
    UnmappedCustomerTabInfo,
    UnmappedCustomerTabRow, UnmappedReport4Info, UnmappedReport5Info,
    UnmappedStore,
    UnmappedUser,
    User
} from "./types";
import {
    CustomerTabInfo,
    Report1Info,
    UnmappedCustomerTabHeader,
    UnmappedCustomerTabRow2,
    UnmappedReport1Info, UnmappedReport3Info
} from "./types";
import _ from 'lodash'
import {formatDate, formatTypes} from "../../helpers/date";
import moment from "moment";

export const mapStore: Store = (datum: UnmappedStore) => {
    if (!datum) return
    return {
        erpCode: datum.AddressErpCode,
        customerErpCode: datum.ErpCode,
        id: datum.AddressId,
        address: {
            el: datum.Address,
            en: datum.AddressEn
        },
        countryCode: datum.AddressCountryCode,
        isAdmin: datum.isAdmin === '1',
        ciIdCode: datum.AddressCiIdCode
    }
}

export const mapUser: User = (datum: UnmappedUser) => {
    if (!datum) return
    return {
        id: datum.UserId,
        loginName: {
            el: datum.LoginName,
            en: datum.LoginNameEn,
        },
        erpCode: datum.ErpCode,
        currencySymbol: datum.UserCurrencySymbol ? datum.UserCurrencySymbol : '€',
        username: datum.username,
        locale: datum.locale?.toLowerCase(),
        orderTypes: datum.userOrdersheets ?
            datum.userOrdersheets.map(t => ({
                id: Number(t.id),
                title: {
                    el: t.Description,
                    en: t.DescriptionEn,
                }
            })) : [],
        routeStartDate: moment(datum.StartDate).toDate(),
        routeRepetition: Number(datum.Repetition),
    }
}

export const mapCustomerTabInfo: CustomerTabInfo = (datum: UnmappedCustomerTabInfo) => {
    if (!datum) return
    const mapTransaction = (i: UnmappedCustomerTabRow) => {
        const documentNumber = !_.isEmpty(i.DocumentCode) ? i.DocumentCode : null
        return ({
            date: i.RegDate,
            documentNumber: documentNumber,
            branch: i.Ypokatastima,
            debitAmount: documentNumber ? Number(i.DebitValue) : null,
            creditAmount: documentNumber ? Number(i.CreditValue) : null,
            remainder: Number(i.CumulBalance),
            reason: i.Reasoning
        });
    }
    const mapTransaction2 = (i: UnmappedCustomerTabRow2) => {
        const documentNumber = !_.isEmpty(i.ADCode) ? i.ADCode : null
        return ({
            date: i.ADRegistrationDate,
            documentNumber: documentNumber,
            branch: i.Description2,
            debitAmount: i.OpenQty ? Number(i.OpenQty) : null,
            creditAmount: i.OpenQtyValue ? Number(i.OpenQtyValue) : null,
            remainder: Number(i.CumulBalance),
            reason: i.ADReasoning
        });
    }
    const mapHeader = (i: UnmappedCustomerTabHeader) => {
        if (!i) return
        return ({
            name: i.Code + "-" + i.Name,
            address: i.Address1,
            postalCode: i.fPostalCode,
            vatNumber: i.TaxRegistrationNumber,
            area: i.Area,
            occupation: i.ActivityDescription,
            city: i.CityDescription,
            phone: i.Telephone1,
        })
    }
    const transactionData = datum.customer_tbl1 && datum.customer_tbl1.length > 0 ?
        datum.customer_tbl1.map(mapTransaction) : []
    const pendingData = datum.customer_tbl2 && datum.customer_tbl2.length > 0 ?
        datum.customer_tbl2.map(mapTransaction2) : []
    return {
        info: mapHeader(datum.customer_tbl_header),
        transactionData: transactionData,
        pending: pendingData,
        transactionDataTotal: {
            remainder: transactionData[transactionData.length - 1].remainder,
            debitAmount: transactionData
                .filter(d => Boolean(d.documentNumber))
                .reduce((p, n) => p + n.debitAmount, 0)
                .toFixed(2),
            creditAmount: transactionData
                .filter(d => Boolean(d.documentNumber))
                .reduce((p, n) => p + n.creditAmount, 0)
                .toFixed(2),
        },
        pendingTotal: {
            pendingQuantity: pendingData
                .reduce((p, n) => p + n.debitAmount, 0)
                .toFixed(2),
            predictedPendingValue: pendingData
                .reduce((p, n) => p + n.creditAmount, 0)
                .toFixed(2),
        },
    }
}

export const mapReport1Info: Report1Info = (datum: UnmappedReport1Info) => {
    if (!datum) return
    return {
        category: {
            id: datum.CodeCategory,
            title: {
                en: datum.EngDecrCategory,
                el: datum.DescriptionCategory,
            }
        },
        subCategory: {
            id: datum.CodeSubCategory,
            title: {
                en: datum.EngSubCategory,
                el: datum.DescriptionSubCategory,
            }
        },
        description: {
            en: datum.ItemAlternativeDescription,
            el: datum.Description
        },
        id: datum.fItemGID,
        price: Number(datum.LineValue),
        quantity: Number(datum.LineQty),
        // category: {
        //     id: datum.ItemCategId,
        //     title: {
        //         en: datum.ItemCategoryDescriptionEn,
        //         el: datum.ItemCategoryDescription,
        //     }
        // },
        // subCategory: {
        //     id: datum.ItemCateg2Id,
        //     title: {
        //         en: datum.ItemSubCategoryDescriptionEn,
        //         el: datum.ItemSubCategoryDescription,
        //     }
        // },
        // description: {
        //     en: datum.ItemDescriptionEn,
        //     el: datum.ItemDescription
        // },
        // id: datum.ItemId,
        // price: Number(datum.priceWithTaxInOrder),
        // quantity: Number(datum.quantity),
    }
}

export const mapReport2Info: Report1Info = (datum: UnmappedReport1Info) => {
    if (!datum) return
    return {
        category: {
            id: datum.CodeCategory,
            title: {
                en: datum.EngDecrCategory,
                el: datum.DescriptionCategory,
            }
        },
        subCategory: {
            id: datum.CodeSubCategory,
            title: {
                en: datum.EngSubCategory,
                el: datum.DescriptionSubCategory,
            }
        },
        description: {
            en: datum.ItemAlternativeDescription,
            el: datum.Description
        },
        date: formatDate(new Date(datum.RegistrationDate.date), formatTypes.DATE_ONLY),
        id: datum.fItemGID,
        price: Number(datum.LineValue),
        quantity: Number(datum.LineQty),
    }
}

export const mapReport3Info: Report3Info = (datum: UnmappedReport3Info) => {
    if (!datum) return
    return {
        category: {
            id: datum.CodeCategory,
            title: {
                en: datum.EngDecrCategory,
                el: datum.DescriptionCategory,
            }
        },
        subCategory: {
            id: datum.CodeSubCategory,
            title: {
                en: datum.EngSubCategory,
                el: datum.DescriptionSubCategory,
            }
        },
        description: {
            en: datum.ItemAlternativeDescription,
            el: datum.Description
        },
        id: datum.Code,
        period1: {
            price: Number(datum.LineValue),
            quantity: Number(datum.LineQty),
        },
        period2: {
            price: Number(datum.LineValue_2),
            quantity: Number(datum.LineQty_2),
        },
    }
}

export const mapReport4Info: Report4Info = (datum: UnmappedReport4Info) => {
    if (!datum) return
    return {
        address: datum.Address,
        countryCode: datum.Code,
        date: moment(datum.CreatedAt).toDate(),
        customer: datum.CustName,
        salesPersonName: datum.SalesPersonName,
        orderSheet: {
            id: datum.OrderSheetId,
            description: {
                en: datum.DescriptionEn,
                el: datum.Description
            }
        },
        id: datum.OrderId,
        transferredDate: datum.TransferedAt ? moment(datum.TransferedAt).toDate() : null,
        transitionDate: datum.TransitionDate ? moment(datum.TransitionDate).toDate() : null,
        statusId: Number(datum.orderStatus),
        price: Number(datum.price).toFixed(2),
        customerErpCode: datum.ucCustomerErpCode,
        addressErpCode: datum.ucCustomerForAddressErpCode,
        ciIdCode: datum.ucIdCode
    }
}

export const mapReport5Info: Report5Info = (datum: UnmappedReport5Info) => {
    if (!datum.length === 0) return
    return datum.map(item => ({
        code: item.Code,
        date: moment(item.CreatedAt).toDate(),
        description: {
            en: item.DescriptionEn,
            el: item.Description
        },
        id: item.OrderId,
        productId: item.ItemId,
        statusId: Number(item.OrderStatus),
        quantity: Number(item.Quantity),
        transferredDate: item.TransferedAt ? moment(item.TransferedAt).toDate() : null,
        transitionDate: item.TransitionDate ? moment(item.TransitionDate).toDate() : null,
    }))
}