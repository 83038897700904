//@flow
import {useCallback} from 'react'
import {useDispatch} from 'react-redux'
import * as actions from '../../actions/actions'
import type {Order} from "../../types/order/types";
import {calculateProductSum, getDiscountQuantity, rowPriceInfoCost} from "../../helpers/helpers";
import {useUserData} from "../user/useUserData";
import {formatDate, formatTypes} from "../../helpers/date";

const useOrderAction = () => {
    const dispatch = useDispatch()
    const {selectedStore} = useUserData()
    const getProductRequestData = useCallback((products) => {
        const sumWithTax = calculateProductSum(products)
        const sumWithoutTax = calculateProductSum(products, false)
        const newProducts = (!(products && products.length > 0)) ? [] :
            products.reduce((p, n) => {
                const {priceWithTax, priceWithoutTax, quantity, invoicePolicy, id, scalingValues, discountWeb} = n
                let result = [
                    ...p,
                    {
                        isDiscount: false,
                        quantity: quantity,
                        ItemId: id,
                        priceWithTax: rowPriceInfoCost(Number(priceWithTax), quantity, null, scalingValues, true, discountWeb),
                        priceWithoutTax: rowPriceInfoCost(Number(priceWithoutTax), quantity, null, scalingValues, false, discountWeb)
                    }
                ]
                if (invoicePolicy) {
                    const discountQuantity = getDiscountQuantity(n, quantity)
                    result = [
                        ...result,
                        {
                            isDiscount: true,
                            quantity: discountQuantity,
                            ItemId: id,
                            priceWithTax: rowPriceInfoCost(Number(priceWithTax), discountQuantity, invoicePolicy.discountPercentage, scalingValues, true, discountWeb),
                            priceWithoutTax: rowPriceInfoCost(Number(priceWithoutTax), discountQuantity, invoicePolicy.discountPercentage, scalingValues, false, discountWeb)
                        }
                    ]
                }
                return result
            }, [])
        return {
            sumWithTax,
            sumWithoutTax,
            products: newProducts
        }
    }, [])

    const getOrders = useCallback((pageIndex, pageSize) => {
        dispatch(actions.getOrders({
                AddressId: selectedStore.id,
                requiresCounts: true,
                skip: pageIndex * pageSize,
                take: pageSize,
                isTemplate: 0
            })
        )
    }, [dispatch, selectedStore.id])

    const getOrderTemplates = useCallback((pageIndex, pageSize) => {
        dispatch(actions.getOrderTemplates({
                AddressId: selectedStore.id,
                requiresCounts: true,
                skip: pageIndex * pageSize,
                take: pageSize,
                isTemplate: 1
            })
        )
    }, [dispatch, selectedStore.id])

    const getOrderDetails = useCallback((id) => {
        dispatch(actions.getOrderDetails({
            AddressId: selectedStore.id,
            id
        }))
    }, [dispatch, selectedStore.id])

    const getActiveOrder = useCallback(() => {
        dispatch(actions.getActiveOrder({
            AddressId: selectedStore.id,
            requiresCounts: true,
            skip: 0,
            take: 20,
            isActive: 1,
            isTemplate: 0
        }))
    }, [dispatch, selectedStore.id])

    const createOrder = useCallback((menuCategoryType, products = []) => {
        const {sumWithTax, sumWithoutTax, products: requestProducts} = getProductRequestData(products)
        dispatch(actions.createOrder({
            AddressId: selectedStore.id,
            OrderSheetId: menuCategoryType,
            totalPriceWithTax: sumWithTax,
            totalPriceWithoutTax: sumWithoutTax,
            products: requestProducts,
            notes: ''
        }))
    }, [dispatch, getProductRequestData, selectedStore.id])

    const cloneOrder = useCallback((orderId, createTemplate, title) => {
        dispatch(actions.cloneOrder({
            AddressId: selectedStore.id,
            OrderId: orderId,
            isTemplate: createTemplate ? 1 : 0,
            ...(title ? {TemplateTitle: title} : {})
        }))
    }, [dispatch, selectedStore.id])

    const updateOrder = useCallback((id, data: Order) => {
        // TODO::Αποστολή ημερομηνία δρομολογίου στο Backend
        const {sumWithTax, sumWithoutTax, products} = getProductRequestData(data.products)
        dispatch(actions.updateOrder({
            AddressId: selectedStore.id,
            id: id,
            notes: data.notes,
            totalPriceWithTax: sumWithTax,
            totalPriceWithoutTax: sumWithoutTax,
            products: products,
            orderStatus: data.statusId,
            acceptBalance: data.acceptBalance,
            DeliveryDate: data.nextRouteDate ? formatDate(data.nextRouteDate, formatTypes.DATE_ONLY_FILTER) : null
        }, data))
    }, [dispatch, getProductRequestData, selectedStore.id])

    const deleteOrder = useCallback((id) => {
        dispatch(actions.deleteOrder({
            AddressId: selectedStore.id,
            id
        }))
    }, [dispatch, selectedStore.id])

    const getOrderBalance = useCallback(() => {
        dispatch(actions.getOrderBalance({
            AddressId: selectedStore.id,
        }))
    }, [dispatch, selectedStore.id])

    return {
        getOrderBalance,
        getOrders,
        getOrderTemplates,
        getOrderDetails,
        getActiveOrder,
        createOrder,
        cloneOrder,
        updateOrder,
        deleteOrder,
    }
}

export default useOrderAction