import * as ActionTypes from '../../actions/types'
import {fromJS} from "immutable";
import {mapMultiple, preserveFieldsIfNotPresentInNew} from "../../helpers/map";
import {mapProduct} from "../../types/product/map";
import _ from "lodash"

export const INITIAL_STATE = {
    byId: {},
    allIndexTable: {
        loading: false,
        items: [],
        count: 0
    },
    banner: {
        loading: false,
        popular: [],
        new: [],
        offers: [],
        favorites: [],
        category: [],
    },
}

const mergeProducts = (state, toStore) => (
    state.get('byId')
        .mergeWith(
            preserveFieldsIfNotPresentInNew(['similar', 'images']), fromJS(toStore)
        )
)

function getProducts(state) {
    return state
        .setIn(['allIndexTable', 'loading'], true)
}

function getProductsSucceeded(state, action) {
    const {payload: {result, count}} = action
    const mappedData = mapMultiple(result, mapProduct)
    const newKeys = Object.keys(mappedData).reverse()
    return state
        .set('byId', mergeProducts(state, mappedData))
        .setIn(['allIndexTable', 'loading'], false)
        .setIn(['allIndexTable', 'count'], Number(count))
        .setIn(['allIndexTable', 'items'], fromJS(newKeys))
}

function getProductsFailed(state) {
    return state
        .setIn(['allIndexTable', 'loading'], false)
}

function getProductSucceeded(state, action) {
    const {item, itemImages, similarItems} = action.payload;
    const mappedSimilarData = mapMultiple(similarItems, mapProduct)
    const mappedData = mapProduct({
        ...item[0],
        itemImages,
        similarItems: mappedSimilarData ? Object.keys(mappedSimilarData) : []
    })
    const toStore = {
        ...mappedSimilarData,
        [mappedData.id]: mappedData
    }
    return state
        .set('byId', mergeProducts(state, toStore))
}

function getSearchResultsSucceeded(state, action) {
    const {payload: {result}} = action
    const mappedData = mapMultiple(result, mapProduct)
    return state
        .set('byId', mergeProducts(state, mappedData))
}

function getPopularAndNewProducts(state) {
    return state
        .setIn(['banner', 'loading'], true)
}

function getPopularAndNewProductsFailed(state) {
    return state
        .setIn(['banner', 'loading'], true)
}

function getPopularAndNewProductsSucceeded(state, action) {
    const {payload: {popularItems, newItems, offerItems, favoriteItems}} = action
    let popularProducts = mapMultiple(popularItems.original.result, mapProduct)
    let newProducts = mapMultiple(newItems.original.result, mapProduct)
    let offerProducts = mapMultiple(offerItems.original.result, mapProduct)
    let favoriteProducts = mapMultiple(favoriteItems.original.result, mapProduct)
    if (!popularProducts) {
        popularProducts = {}
    }
    if (!newProducts) {
        newProducts = {}
    }
    if (!offerProducts) {
        offerProducts = {}
    }
    if (!favoriteProducts) {
        favoriteProducts = {}
    }
    return state
        .setIn(['banner', 'loading'], false)
        .set('byId', mergeProducts(state, {
            ...popularProducts,
            ...newProducts,
            ...offerProducts,
            ...favoriteProducts
        }))
        .updateIn(['banner', 'favorites'], items => !_.isEmpty(favoriteProducts) ? fromJS(Object.keys(favoriteProducts)) : items)
        .updateIn(['banner', 'offers'], items => !_.isEmpty(offerProducts) ? fromJS(Object.keys(offerProducts)) : items)
        .updateIn(['banner', 'popular'], items => !_.isEmpty(popularProducts) ? fromJS(Object.keys(popularProducts)) : items)
        .updateIn(['banner', 'new'], items => !_.isEmpty(newProducts) ? fromJS(Object.keys(newProducts)) : items)
}

function favoriteProductSucceeded(state, action) {
    const {parameters: {ItemId: id, isFavorite}} = action
    return state
        .setIn(['byId', id, 'isFavorite'], isFavorite)
        .updateIn(['banner', 'favorites'], items => isFavorite ? items.push(id) : items.filter(key => key !== id))
}

function saveOrderProducts(state, action) {
    const {payload: {orderItems = []}} = action
    const mappedData = mapMultiple(orderItems, mapProduct)
    return state
        .set('byId', mergeProducts(state, mappedData))
}

function saveActiveOrderProducts(state, action) {
    const {payload: {result = []}} = action
    const products = result.reduce((p, n) => {
        const {orderItems = []} = n
        return [...p, ...orderItems]
    }, [])
    const mappedData = mapMultiple(products, mapProduct)
    return state
        .set('byId', mergeProducts(state, mappedData))
}

function getPopularPerCategory(state) {
    return state
        .set('loading', true);
}

function getPopularPerCategorySucceeded(state, action) {
    if (!action.payload && action.payload.length > 0) {
        return state
            .set('loading', false)
    }
    const mappedData = mapMultiple(action.payload, mapProduct)
    const newKeys = Object.keys(mappedData).reverse()
    return state
        .set('loading', false)
        .set('byId', mergeProducts(state, mappedData))
        .setIn(['banner', 'category'], fromJS(newKeys))
}

function getPopularPerCategoryFailed(state) {
    return state
        .set('loading', false);
}

const productCached = {
    initialState: INITIAL_STATE,
    handlers: {
        [ActionTypes.GET_PRODUCTS]: getProducts,
        [ActionTypes.GET_PRODUCTS_SUCCEEDED]: getProductsSucceeded,
        [ActionTypes.GET_PRODUCTS_FAILED]: getProductsFailed,
        [ActionTypes.GET_PRODUCT_SUCCEEDED]: getProductSucceeded,
        [ActionTypes.GET_SEARCH_RESULTS_SUCCEEDED]: getSearchResultsSucceeded,
        [ActionTypes.GET_ORDER_DETAILS_SUCCEEDED]: saveOrderProducts,
        [ActionTypes.GET_ACTIVE_ORDER_SUCCEEDED]: saveActiveOrderProducts,
        [ActionTypes.FAVORITE_PRODUCT_SUCCEEDED]: favoriteProductSucceeded,

        [ActionTypes.GET_POPULAR_PER_CATEGORY]: getPopularPerCategory,
        [ActionTypes.GET_POPULAR_PER_CATEGORY_SUCCEEDED]: getPopularPerCategorySucceeded,
        [ActionTypes.GET_POPULAR_PER_CATEGORY_FAILED]: getPopularPerCategoryFailed,

        [ActionTypes.GET_POPULAR_AND_NEW_PRODUCTS]: getPopularAndNewProducts,
        [ActionTypes.GET_POPULAR_AND_NEW_PRODUCTS_SUCCEEDED]: getPopularAndNewProductsSucceeded,
        [ActionTypes.GET_POPULAR_AND_NEW_PRODUCTS_FAILED]: getPopularAndNewProductsFailed,
    }
}

export default productCached