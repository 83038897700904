// @flow
import React from 'react';
import Icon from "../icon/icon";
import Images from "../../assets/images";
import {useTranslation} from "react-i18next";
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import Button from "../button/button";
import {getDiscountQuantity} from "../../helpers/helpers";
import {useOrderData} from "../../hooks/order/useOrderData";
import Util from "../util/util";

type Props = {
    type: number,
    onRemove: Function
}

const PreviewAdded = (props: Props) => {
    const {product, type, onRemove} = props;
    const {t} = useTranslation(PRODUCT_COMPONENT)
    let quantity = 0
    const {activeOrder} = useOrderData()
    const activeOrderOfType = activeOrder.find(o => o.type === type)
    if (activeOrderOfType) {
        const {products: activeOrderProducts} = activeOrderOfType
        const productInActiveOrder = activeOrderProducts.find(p => p.id === product.id)
        if (productInActiveOrder) {
            quantity = Number(productInActiveOrder.quantity)
        }
    } else if (product.quantity) {
        quantity = product.quantity
    }
    const discountQuantity = getDiscountQuantity(product, quantity)
    if (!activeOrderOfType || quantity === 0) return null
    return (
        <div className={'preview__added'}>
            <Icon icon={Images.icons.tick}/>
            <Util justifyContent={'initial'} style={{width: '100%', paddingRight: '.5rem'}}>
                <div>
                    <strong>#{activeOrderOfType.code}</strong>
                    {' - '}
                    {activeOrderOfType.typeDescription}
                    {' - '}
                    {t('Added')}
                    {' '}
                    {/*<strong>{discountQuantity ? (discountQuantity + quantity).toFixed(0) : quantity} {product.unitOfMeasurement}</strong>*/}
                    <strong>{quantity} {product.unitOfMeasurement}</strong>
                </div>
                <div style={{marginLeft: '.5rem'}}>
                    <Button variant={'link'} onClick={() => onRemove(activeOrderOfType, type)}>
                        {t('Remove')}
                    </Button>
                </div>
            </Util>
        </div>
    )
}

export default PreviewAdded