import type {
    Label,
    Product,
    ProductCategory,
    ScalingValue,
    UnmappedLabel,
    UnmappedProduct,
    UnmappedProductCategory,
    UnmappedSetItems
} from "./types";
import {API, AvailabilityStatus} from "../../helpers/constants";
import {applyPercentage, getMatchingScalingValue} from "../../helpers/helpers";
import {mapMultiple} from "../../helpers/map";
import _ from "lodash"

export const mapProductCategory: ProductCategory = (orderSheetCategoryId, subCategories) => (datum: UnmappedProductCategory) => {
    if (!datum) return
    return {
        id: datum.CategoryId,
        image: datum.Image ? API + '/' + datum.Image : null,
        title: {
            el: datum.Description,
            en: datum.DescriptionEn
        },
        weight: Number(datum.PriorityNum),
        parent: datum.ParentCategoryId,
        children: subCategories && subCategories.length > 0 ? subCategories
            .filter(c => c.ParentCategoryId === datum.CategoryId)
            .map(mapProductCategory(orderSheetCategoryId)) : null,
        typeId: orderSheetCategoryId
    }
}

const mapLabel: Label = (datum: UnmappedLabel) => {
    if (!datum) return
    return {
        description: {
            en: datum.ItemLabelDescriptionEn,
            el: datum.ItemLabelDescription,
        },
        id: datum.LabelId,
        backgroundColor: '#' + datum.LabelBackgroundColor,
        color: '#' + datum.LabelFontColor,
        weight: Number(datum.LabelDisplayOrder),
    }
}

const mapAvailabilityStatus = (status) => {
    switch (status) {
        case "Διαθέσιμο":
            return AvailabilityStatus.Available
        case "Μη Διαθέσιμο":
            return AvailabilityStatus.OutOfStock
        case "Περιορισμένη Διαθεσιμότητα":
            return AvailabilityStatus.Limited
        default:
    }
    return status
}

const mapScalingValues: Array<ScalingValue> = (datum, taxPercentage) => {
    if (!(datum && datum.length > 0)) return null
    return datum.map(r => ({
        priceWithoutTax: Number(r.priceWithoutTax),
        priceWithTax: applyPercentage(r.priceWithoutTax, taxPercentage),
        fromQuantity: Number(r.FromQuantity)
    }))
}

const mapSetScalingValues = (setItems: UnmappedSetItems) => {
    const items = Object
        .keys(setItems)
        .map(key => setItems[key])
    const scalingItems = items
        .map(item => {
            const {ScallingValues, ItemVat, Qty} = item
            const quantity = Number(Qty)
            const scalingValues = mapScalingValues(ScallingValues, ItemVat)
            let multiplier = 0
            let toKeep = []
            const maximumFromQuantity = _.max(scalingValues.map(s => s.fromQuantity))
            let useQuantity = 0
            do {
                multiplier++
                useQuantity = multiplier * quantity
                let match = getMatchingScalingValue(scalingValues, useQuantity)
                toKeep.push({
                    priceWithoutTax: (useQuantity * match.priceWithoutTax) / multiplier,
                    priceWithTax: (useQuantity * match.priceWithTax) / multiplier,
                    fromQuantity: multiplier
                })
            } while (maximumFromQuantity > useQuantity)
            return toKeep
        })
    const allQuantities = scalingItems.reduce((p, n) => {
        return [
            ...p,
            ...n.map(v => v.fromQuantity).filter(v => !p.includes(v))
        ]
    }, [])
    const newItems = scalingItems.map(scalingValues => {
        return allQuantities.map(quantity => {
            const match = getMatchingScalingValue(scalingValues, quantity)
            return {
                ...match,
                fromQuantity: quantity
            }
        })
    })
    const final = _(_.flatten(newItems))
        .groupBy('fromQuantity')
        .map((j, fromQuantity) => ({
            fromQuantity: Number(fromQuantity),
            priceWithTax: _.sumBy(j, 'priceWithTax'),
            priceWithoutTax: _.sumBy(j, 'priceWithoutTax')
        }))
        .value()
    const first = final.find(v => v.fromQuantity === 1)
    final.push({
        ...first,
        fromQuantity: 0
    })
    return final
}

export const mapProduct: Product = (datum: UnmappedProduct) => {
    if (!datum) return
    const images = (datum.itemImages && datum.itemImages.length > 0) ? {
        images: datum.itemImages.map(i => ({
            original: API + '/' + i.ImageFile,
            thumbnail: API + '/' + i.ImageFile,
            isMainImage: i.isMainImage === "1"
        }))
    } : {}
    const priceWithoutTax = Number(datum.priceWithoutTax)
    const taxPercentage = Number(datum.ItemVat)
    const packaging = datum.ItemPackaging ? datum.ItemPackaging.split(' ') : null
    const packagingEn = datum.ItemPackagingEn ? datum.ItemPackagingEn.split(' ') : null
    const isSet = datum.isSet === "1"
    const calculateSetPrice = datum.isSetPriceCalc === "1"

    const finalScalingValues = (isSet && calculateSetPrice) ?
        mapSetScalingValues(datum.setItems)
        : datum.ScallingValues ?
            mapScalingValues(datum.ScallingValues, taxPercentage) : null
    return {
        isSet,
        calculateSetPrice,
        labels: datum.ItemLabels ?
            mapMultiple(
                datum.ItemLabels
                    .split(',')
                    .map((l, i) => {
                        const splittedLabel = l.split('#')
                        return {
                            LabelId: splittedLabel[0] + i,
                            ItemLabelDescription: splittedLabel[0],
                            ItemLabelDescriptionEn: splittedLabel[1],
                            LabelBackgroundColor: splittedLabel[2],
                            LabelDisplayOrder: i,
                            LabelFontColor: splittedLabel[3],
                        }
                    }), mapLabel) : [],
        isFavorite: datum.isFavorite === "1",
        thumbnail: datum.ImageFile ? API + '/' + datum.ImageFile : null,
        discountWeb: Number(datum.DiscountWeb),
        popularWeight: Number(datum.B2BPopularOrderDisplay),
        weight: Number(datum.ItemPriorityNum),
        description: {
            en: datum.ItemSummaryEn,
            el: datum.ItemSummary,
        },
        message: {
            en: datum.ItemWebDescriptionEn,
            el: datum.ItemWebDescription,
        },
        exclusiveToOrderTypes: datum.OrderSheets ? datum.OrderSheets.split(',').map((i) => Number(i)) : [],
        availabilityStatus: mapAvailabilityStatus(datum.availability),
        multiplier: packaging && packaging.length > 0 ? Number(packaging[0]) : 1,
        id: datum.ItemId,
        unitOfMeasurement: {
            el: datum.unitOfMeasurement,
            en: datum.unitOfMeasurementEn
        },
        basicUnitOfMeasurement: {
            el: packaging && packaging.length > 0 ? packaging.slice(1).join(' ') : datum.unitOfMeasurement,
            en: packagingEn && packagingEn.length > 0 ? packagingEn.slice(1).join(' ') : datum.unitOfMeasurementEn
        },
        itemStock: datum.itemStock,
        title: {
            el: datum.ItemDescription,
            en: datum.ItemDescriptionEn,
        },
        categoryId: datum.ItemCategId,
        subCategoryId: datum.ItemCateg2Id,
        category: {
            id: datum.ItemCategId,
            weight: Number(datum.PriorityNum),
            title: {
                el: datum.ItemCategoryDescription,
                en: datum.ItemCategoryDescriptionEn
            }
        },
        subCategory: {
            id: datum.ItemCateg2Id,
            weight: Number(datum.SubCategoryPriorityNum),
            title: {
                el: datum.ItemSubCategoryDescription,
                en: datum.ItemSubCategoryDescriptionEn
            }
        },
        code: datum.Code,
        taxPercentage: taxPercentage,
        scalingValues: finalScalingValues,
        priceWithTax: applyPercentage(priceWithoutTax, taxPercentage),
        priceWithoutTax: priceWithoutTax.toFixed(2),
        retailPrice: Number(datum.retailPrice).toFixed(2),
        step: Number(datum.NormalQuantity),
        invoicePolicy: datum.hasInvoicePolicy === "1" ? {
            appliesFromQuantity: Number(datum.FromQuantity),
            normalQuantity: Number(datum.NormalQuantity),
            discountQuantity: Number(datum.DiscountQuantity),
            discountPercentage: Number(datum.PolicyDiscount),
        } : null,
        ...(datum.similarItems ? {
            similar: datum.similarItems
        } : {}),
        ...images
    }
}