//@flow
import React from 'react';
import Title from "../title/title";
import Number from "../form/elements/number/number";
import {useTranslation} from "react-i18next";
import {PRODUCT_COMPONENT} from "../../locales/components/namespaces";
import type {Product} from "../../types/product/types";
import {rowPriceInfoCost, rowPriceInfoPricePerUnit} from "../../helpers/helpers";
import {useUserData} from "../../hooks/user/useUserData";
import CrossedPrice from "../crossedPrice/crossedPrice";

type Props = {
    step?: number,
    useDiscount?: boolean,
    product: Product,
    quantity: number,
    disabled?: boolean,
    onQuantityChange?: Function,
    onQuantitySelected?: Function
};

const ProductQuantitySelect = (props: Props) => {
    const {t} = useTranslation(PRODUCT_COMPONENT)
    const {
        step,
        showCreatingError,
        showRoundError,
        useDiscount,
        disabled,
        quantity,
        onQuantityChange,
        onBlur,
        product: {
            unitOfMeasurement,
            priceWithoutTax,
            priceWithTax,
            invoicePolicy,
            scalingValues,
            discountWeb
        },
    } = props

    const {data} = useUserData()
    const cost = rowPriceInfoCost(priceWithTax, quantity, useDiscount ? invoicePolicy.discountPercentage : null, scalingValues, true, discountWeb)

    const pricePerUnit = rowPriceInfoPricePerUnit(priceWithoutTax, quantity, useDiscount ? invoicePolicy.discountPercentage : null, scalingValues, discountWeb)
    const pricePerUnitWithoutWebDiscount = rowPriceInfoPricePerUnit(priceWithoutTax, quantity, useDiscount ? invoicePolicy.discountPercentage : null, scalingValues, null)

    return (
        <div className={'preview__quantity-select'}>
            {/*<Title*/}
            {/*    defaultFont*/}
            {/*    subtitle={t('without_tax')}*/}
            {/*    variant={"body"}>*/}
            {/*    {discountWeb && !disabled ? <CrossedPrice price={pricePerUnitWithoutWebDiscount} currency={data?.currencySymbol} /> : null}*/}
            {/*    <span>{pricePerUnit && pricePerUnit.toFixed ? pricePerUnit.toFixed(2) : pricePerUnit}{data?.currencySymbol}/{unitOfMeasurement}</span>*/}
            {/*</Title>*/}
            <div
                className={'preview__input'}
                {...(Boolean(step) && showRoundError) ? {
                    'data-tooltip': t('round_error', {step}),
                    'data-tooltip-visible': true
                } : {}}
                {...(showCreatingError) ? {
                    'data-tooltip': t('create_error'),
                    'data-tooltip-visible': true
                } : {}}
            >
                {disabled ?
                    <span>x{quantity}</span> : (
                        <Number
                            value={quantity}
                            min={0}
                            step={step}
                            onChange={onQuantityChange}
                            onBlur={onBlur}
                        />
                    )}
            </div>
            {/*<Title*/}
            {/*    defaultFont*/}
            {/*    variant={"body"}*/}
            {/*    subtitle={t('with_tax')}*/}
            {/*>*/}
            {/*    {cost.toFixed(2)}{data?.currencySymbol}*/}
            {/*</Title>*/}
        </div>
    )
}

export default ProductQuantitySelect;