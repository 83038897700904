let mode: string;

if (process.env.NODE_ENV) {
    mode = process.env.NODE_ENV;
} else {
    mode = 'production';
}

const globalConfiguration = mode !== "test" ?
    window.GLOBAL_CONFIGURATION
    : {
        test: {
            API_HOST: "http://192.168.191.41:8054"
        },
        customerTabDisabledBeforeDate: new Date('2022', 0, 1),
        portalToken: '6F9619FF-8B86-D011-B42D-00C04FC964FF'
    }

export const customerTabDisabledBeforeDate = globalConfiguration.customerTabDisabledBeforeDate

let API_URL: string = "";
if (globalConfiguration[mode] &&
    globalConfiguration[mode].API_HOST) {
    API_URL = typeof globalConfiguration[mode].API_HOST === "string" ?
        globalConfiguration[mode].API_HOST :
        globalConfiguration[mode].API_HOST[window.location.hostname];
}

export const API = API_URL
export const PortalToken = globalConfiguration.portalToken

export const AnnouncementTypes = {
    All: 0,
    Eshop: 49,
    Company: 50,
    Newsletter: 51,
}

export const Permissions = {
    AccessReports: "ACCESS_REPORTS",
    AccessPayments: "ACCESS_PAYMENTS",
    AccessAdmin: "ACCESS_ADMIN",
    AccessSalesPerson: "ACCESS_SALESPERSON"
}

export const ReadStatus = {
    Any: 0,
    Read: 1,
    Unread: 2,
}

export const AvailabilityStatus = {
    Available: 0,
    Limited: 1,
    OutOfStock: 2,
}

export const OrderStatus = {
    Change: 0,
    Sent: 1,
    InProgress: 2,
    ForDelivery: 4
}

export const Languages = {
    EL: 'el',
    EN: 'en',
}

export const MESSAGE_ACTION_TYPE_B2B = 8