import type {Address, Order, UnmappedAddress, UnmappedOrder} from "./types";
import {formatDate, formatTypes} from "../../helpers/date";
import _ from "lodash"
import moment from "moment";

const mapAddress: Address = (datum: UnmappedAddress) => {
    if (!datum) return
    return {
        id: datum.AddressId,
        address: datum.Address,
        city: datum.City,
        postalCode: datum.ZipCode,
        countryCode: datum.CountryId
    }
}

export const mapOrderProducts = (datum, isActive) => {
    if (!datum) return []
    return Object
        .keys(datum)
        .map(ItemId => {
            const item = datum[ItemId]
            const withDiscountPolicyApplied = item.slice(1)
            const quantity = Number(item[0].quantity)
            return {
                id: ItemId,
                quantity: quantity,
                ...!isActive ? {
                    priceWithTax: Number(item[0].priceWithTaxInOrder) / quantity,
                    priceWithoutTax: Number(item[0].priceWithoutTaxInOrder) / quantity,
                } : {},
                withDiscountPolicyApplied: withDiscountPolicyApplied.length > 0 ?
                    withDiscountPolicyApplied.map(i => ({
                        id: ItemId,
                        quantity: Number(i.quantity),
                    })) : null
            }
        })
}

export const mapOrder: Order = (datum: UnmappedOrder) => {
    // TODO::Νέο πεδίο για ημερομηνία δρομολογίου
    if (!datum) return
    const products = datum.orderItems ?
        datum.orderItems : []
    const orderSheet = datum.OrderSheet && datum.OrderSheet[0] ? datum.OrderSheet[0] : null
    const date = moment(datum.CreatedAt).toDate()
    const isTemplate = datum.isTemplate === "1"
    const statusId = Number(datum.orderStatus)
    return {
        isTemplate: isTemplate,
        id: datum.OrderId.toString(),
        date: date,
        transferredDate: datum.TransferedAt ? moment(datum.TransferedAt).toDate() : null,
        transitionDate: datum.TransitionDate ? moment(datum.TransitionDate).toDate() : null,
        title: isTemplate ? datum.TemplateTitle : formatDate(date, formatTypes.TITLE),
        price: Number(datum.price).toFixed(2),
        statusId: statusId,
        products: mapOrderProducts(_.groupBy(products, 'ItemId'), statusId === 0),
        notes: datum.notes ? datum.notes : '',
        code: datum.Code ? datum.Code.replace('Κωδικός - ', '') : '',
        // billing: mapAddress(datum.address[0]),
        // shipping: mapAddress(datum.address[0]),
        store: datum.address && datum.address.length > 0 ? mapAddress(datum.address[0]) : null,
        type: Number(datum.OrderSheetId),
        typeDescription: {
            el: orderSheet?.Description,
            en: orderSheet?.DescriptionEn,
        },
        acceptBalance: datum.AcceptBalance === '1',
        orderSheetId: datum.OrderSheetId,
        orderSheetIsInternal: orderSheet.isInternal === '1',
        nextRouteDate: datum.DeliveryDate ? moment(datum.DeliveryDate).toDate() : null
    }
}