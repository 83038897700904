import {actionCreator} from "../helpers/actionCreator";

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCEEDED = 'USER_LOGIN_SUCCEEDED';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_SUCCEEDED = 'USER_LOGOUT_SUCCEEDED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const GET_USER_STORES = 'GET_USER_STORES';
export const GET_USER_STORES_SUCCEEDED = 'GET_USER_STORES_SUCCEEDED';
export const GET_USER_STORES_FAILED = 'GET_USER_STORES_FAILED';

export const SET_USER_STORE = 'SET_USER_STORE';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCEEDED = 'GET_USER_DETAILS_SUCCEEDED';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES_SUCCEEDED = 'GET_PRODUCT_CATEGORIES_SUCCEEDED';
export const GET_PRODUCT_CATEGORIES_FAILED = 'GET_PRODUCT_CATEGORIES_FAILED';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCEEDED = 'GET_PRODUCTS_SUCCEEDED';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const GET_POPULAR_AND_NEW_PRODUCTS = 'GET_POPULAR_AND_NEW_PRODUCTS';
export const GET_POPULAR_AND_NEW_PRODUCTS_SUCCEEDED = 'GET_POPULAR_AND_NEW_PRODUCTS_SUCCEEDED';
export const GET_POPULAR_AND_NEW_PRODUCTS_FAILED = 'GET_POPULAR_AND_NEW_PRODUCTS_FAILED';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCEEDED = 'GET_PRODUCT_SUCCEEDED';
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED';

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCEEDED = 'GET_ORDERS_SUCCEEDED';
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED';

export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const GET_ORDER_DETAILS_SUCCEEDED = 'GET_ORDER_DETAILS_SUCCEEDED';
export const GET_ORDER_DETAILS_FAILED = 'GET_ORDER_DETAILS_FAILED';

export const GET_ACTIVE_ORDER = 'GET_ACTIVE_ORDER';
export const GET_ACTIVE_ORDER_SUCCEEDED = 'GET_ACTIVE_ORDER_SUCCEEDED';
export const GET_ACTIVE_ORDER_FAILED = 'GET_ACTIVE_ORDER_FAILED';

export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_ORDER_SUCCEEDED = 'CREATE_ORDER_SUCCEEDED';
export const CREATE_ORDER_FAILED = 'CREATE_ORDER_FAILED';

export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCEEDED = 'UPDATE_ORDER_SUCCEEDED';
export const UPDATE_ORDER_FAILED = 'UPDATE_ORDER_FAILED';

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCEEDED = 'DELETE_ORDER_SUCCEEDED';
export const DELETE_ORDER_FAILED = 'DELETE_ORDER_FAILED';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCEEDED = 'GET_MESSAGES_SUCCEEDED';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const SET_MESSAGE_VIEWED = 'SET_MESSAGE_VIEWED';
export const SET_MESSAGE_VIEWED_SUCCEEDED = 'SET_MESSAGE_VIEWED_SUCCEEDED';
export const SET_MESSAGE_VIEWED_FAILED = 'SET_MESSAGE_VIEWED_FAILED';

export const GET_MESSAGE_ATTACHMENTS = 'GET_MESSAGE_ATTACHMENTS';
export const GET_MESSAGE_ATTACHMENTS_SUCCEEDED = 'GET_MESSAGE_ATTACHMENTS_SUCCEEDED';
export const GET_MESSAGE_ATTACHMENTS_FAILED = 'GET_MESSAGE_ATTACHMENTS_FAILED';

export const GET_MESSAGE_DETAILS = 'GET_MESSAGE_DETAILS';
export const GET_MESSAGE_DETAILS_SUCCEEDED = 'GET_MESSAGE_DETAILS_SUCCEEDED';
export const GET_MESSAGE_DETAILS_FAILED = 'GET_MESSAGE_DETAILS_FAILED';

export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const GET_SEARCH_RESULTS_SUCCEEDED = 'GET_SEARCH_RESULTS_SUCCEEDED';
export const GET_SEARCH_RESULTS_FAILED = 'GET_SEARCH_RESULTS_FAILED';

export const GET_ORDER_TEMPLATES = 'GET_ORDER_TEMPLATES';
export const GET_ORDER_TEMPLATES_SUCCEEDED = 'GET_ORDER_TEMPLATES_SUCCEEDED';
export const GET_ORDER_TEMPLATES_FAILED = 'GET_ORDER_TEMPLATES_FAILED';

export const CLONE_ORDER = 'CLONE_ORDER';
export const CLONE_ORDER_SUCCEEDED = 'CLONE_ORDER_SUCCEEDED';
export const CLONE_ORDER_FAILED = 'CLONE_ORDER_FAILED';

export const GET_CUSTOMER_TAB_INFO = 'GET_CUSTOMER_TAB_INFO';
export const GET_CUSTOMER_TAB_INFO_SUCCEEDED = 'GET_CUSTOMER_TAB_INFO_SUCCEEDED';
export const GET_CUSTOMER_TAB_INFO_FAILED = 'GET_CUSTOMER_TAB_INFO_FAILED';

export const GET_REPORT_1_INFO = 'GET_REPORT_1_INFO';
export const GET_REPORT_1_INFO_SUCCEEDED = 'GET_REPORT_1_INFO_SUCCEEDED';
export const GET_REPORT_1_INFO_FAILED = 'GET_REPORT_1_INFO_FAILED';

export const GET_REPORT_2_INFO = 'GET_REPORT_2_INFO';
export const GET_REPORT_2_INFO_SUCCEEDED = 'GET_REPORT_2_INFO_SUCCEEDED';
export const GET_REPORT_2_INFO_FAILED = 'GET_REPORT_2_INFO_FAILED';

export const GET_REPORT_3_INFO = 'GET_REPORT_3_INFO';
export const GET_REPORT_3_INFO_SUCCEEDED = 'GET_REPORT_3_INFO_SUCCEEDED';
export const GET_REPORT_3_INFO_FAILED = 'GET_REPORT_3_INFO_FAILED';

export const GET_REPORT_4_INFO = 'GET_REPORT_4_INFO';
export const GET_REPORT_4_INFO_SUCCEEDED = 'GET_REPORT_4_INFO_SUCCEEDED';
export const GET_REPORT_4_INFO_FAILED = 'GET_REPORT_4_INFO_FAILED';

export const GET_REPORT_5_INFO = 'GET_REPORT_5_INFO';
export const GET_REPORT_5_INFO_SUCCEEDED = 'GET_REPORT_5_INFO_SUCCEEDED';
export const GET_REPORT_5_INFO_FAILED = 'GET_REPORT_5_INFO_FAILED';

export const FAVORITE_PRODUCT = 'FAVORITE_PRODUCT';
export const FAVORITE_PRODUCT_SUCCEEDED = 'FAVORITE_PRODUCT_SUCCEEDED';
export const FAVORITE_PRODUCT_FAILED = 'FAVORITE_PRODUCT_FAILED';

export const GET_POPULAR_PER_CATEGORY = 'GET_POPULAR_PER_CATEGORY';
export const GET_POPULAR_PER_CATEGORY_SUCCEEDED = 'GET_POPULAR_PER_CATEGORY_SUCCEEDED';
export const GET_POPULAR_PER_CATEGORY_FAILED = 'GET_POPULAR_PER_CATEGORY_FAILED';

export const GET_ORDER_BALANCE = 'GET_ORDER_BALANCE';
export const GET_ORDER_BALANCE_SUCCEEDED = 'GET_ORDER_BALANCE_SUCCEEDED';
export const GET_ORDER_BALANCE_FAILED = 'GET_ORDER_BALANCE_FAILED';

export const GET_BANNERS = 'GET_BANNERS';
export const GET_BANNERS_SUCCEEDED = 'GET_BANNERS_SUCCEEDED';
export const GET_BANNERS_FAILED = 'GET_BANNERS_FAILED';

export const GET_SALES_PERSON_UNDERLINGS = 'GET_SALES_PERSON_UNDERLINGS';
export const GET_SALES_PERSON_UNDERLINGS_SUCCEEDED = 'GET_SALES_PERSON_UNDERLINGS_SUCCEEDED';
export const GET_SALES_PERSON_UNDERLINGS_FAILED = 'GET_SALES_PERSON_UNDERLINGS_FAILED';

export const GET_PAYMENTS = 'GET_PAYMENTS';
export const GET_PAYMENTS_SUCCEEDED = 'GET_PAYMENTS_SUCCEEDED';
export const GET_PAYMENTS_FAILED = 'GET_PAYMENTS_FAILED';

export const GET_PAYMENT_TICKETING_SOAP = 'GET_PAYMENT_TICKETING_SOAP';
export const GET_PAYMENT_TICKETING_SOAP_SUCCEEDED = 'GET_PAYMENT_TICKETING_SOAP_SUCCEEDED';
export const GET_PAYMENT_TICKETING_SOAP_FAILED = 'GET_PAYMENT_TICKETING_SOAP_FAILED';