//@flow
import React, {useCallback, useState} from 'react';
import {useUserData} from "../../../hooks/user/useUserData";
import {useTranslation} from "react-i18next";
import useUserAction from "../../../hooks/user/useUserAction";
import Title from "../../title/title";
import Divider from "../../divider/divider";
import RangeSelect from "../../rangeSelect/rangeSelect";
import moment from "moment";
import {PAGE_REPORTS} from "../../../locales/pages/namespaces";
import Util from "../../util/util";
import Table from "../../table/table";
import './report4.css'
import {withPermissionHOC} from "../../../modules/permission/components/permissionHOC/permissionHOC";
import {Permissions} from "../../../helpers/constants";
import DropdownFilter from "../../table/partials/filters/dropdownFilter/dropdownFilter";
import TextFilter from "../../table/partials/filters/textFilter/textFilter";
import NumberRangeFilter from "../../table/partials/filters/numberRangeFilter/numberRangeFilter";
import OrderStatus from "../../orderStatus/orderStatus";
import {formatDate, formatTypes} from "../../../helpers/date";
import useSalesPersons from "../../../hooks/salesPersons/useSalesPersons";
import SelectSalesPerson from "../../selectSalesPerson/selectSalesPerson";

const NumberCell = ({value}) => value && (
    <div style={{textAlign: "right"}}>
        {Number(value).toFixed(2)}
    </div>
)

const Report4 = () => {
    const {t} = useTranslation([PAGE_REPORTS])
    const {data} = useUserData()
    const {report4IndexTable} = useUserData()
    const {getReport4Info} = useUserAction()
    const {salesPersons} = useSalesPersons()
    const [selectedSalesPerson, setSelectedSalesPerson] = useState(data.id)

    const [range, setRange] = useState([
        moment().subtract(31, 'days').toDate(), new Date()
    ])
    const onSubmit = (values) => {
        const {range} = values
        setRange(range)
    }

    const dataManager = useCallback((pageIndex, pageSize) => {
        const salesPersonsIds = selectedSalesPerson === 'all' ? salesPersons.map(salesPerson => salesPerson.id).toString() : selectedSalesPerson
        getReport4Info(pageIndex, pageSize, range, salesPersonsIds)
    }, [getReport4Info, range, selectedSalesPerson])

    return (
        <div className={'report1-page contained-page bg-container'}>
            <Util>
                <Title variant={"h3"}>
                    {t(PAGE_REPORTS + ':SALES PERSON ORDERS')}
                </Title>
            </Util>
            <Divider/>
            <RangeSelect
                initialRange={range}
                onSubmit={onSubmit}
            />
            <SelectSalesPerson
                value={selectedSalesPerson}
                onChange={(evt) => setSelectedSalesPerson(evt.target.value)}
                salesPersons={salesPersons}
            />
            <br/>
            <div className="table-wrapper">
                <Table
                    data={report4IndexTable}
                    pageSize={10}
                    dataManager={dataManager}
                    noMeta
                    noPagination
                    customStyle
                    columns={[
                        {
                            Header: t('order'),
                            accessor: 'id',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Sales Person'),
                            accessor: 'salesPersonName',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('date'),
                            accessor: 'date',
                            Cell: ({value}) => value && (
                                <div>
                                    <strong>{formatDate(value, formatTypes.TITLE_HALF)} </strong>
                                    <span>({formatDate(value, formatTypes.TIME_ONLY)})</span>
                                </div>
                            )
                        },
                        {
                            Header: t('orderSheet'),
                            accessor: 'orderSheet.description',
                            Filter: DropdownFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Address Erp Code'),
                            accessor: 'addressErpCode',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('customer'),
                            accessor: 'customer',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('address'),
                            accessor: 'address',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('Country Code'),
                            accessor: 'countryCode',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('ciIdCode'),
                            accessor: 'ciIdCode',
                            Filter: TextFilter,
                            filter: 'includes',
                        },
                        {
                            Header: t('price'),
                            accessor: 'price',
                            Filter: NumberRangeFilter,
                            filter: 'between',
                            Cell: NumberCell,
                        },
                        {
                            Header: t('transferredDate'),
                            accessor: 'transferredDate',
                            Cell: ({value}) => value && (
                                <div>
                                    <strong>{formatDate(value, formatTypes.TITLE_HALF)} </strong>
                                    <span>({formatDate(value, formatTypes.TIME_ONLY)})</span>
                                </div>
                            )
                        },
                        {
                            Header: t('status'),
                            accessor: 'statusId',
                            Cell: ({value, row}) => {
                                return value !== null && (
                                    <div style={{textAlign: "center"}}>
                                        <div style={{textAlign: "center"}}>
                                            <OrderStatus status={value}/>
                                        </div>
                                    </div>
                                );
                            }
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default withPermissionHOC(Report4, [Permissions.AccessSalesPerson])
