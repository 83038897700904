//@flow
import {createSelector} from 'reselect'
import {useSelector} from "react-redux";
import {parseMultipleFromReference} from "../../helpers/parse";
import {parseOrder} from "../../types/order/parse";

const selectCreatingOrder = createSelector(
    (state) => state.order,
    (item) => item.get('creatingOrder')
)

const selectCloneOrder = createSelector(
    [
        (state) => state.order.get('cloneOrder')
    ],
    (item) => ({
        error: item.get('error'),
        created: item.get('created'),
        loading: item.get('loading'),
    })
)

const selectLoading = createSelector(
    (state) => state.order.get('loading'),
    (item) => item
)

const selectIndexTable = createSelector(
    [
        (state) => state.order.get('indexTable'),
        (state) => state.order.get('byId'),
        (state) => state.currentUser.get('language'),
        (state) => state.productCached.get('byId'),
    ],
    (indexTable, byId, language, referencedProductsById) => ({
        loading: indexTable.get('loading'),
        count: indexTable.get('count'),
        items: parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseOrder(i, language, referencedProductsById)),
    })
)

const selectTemplatesIndexTable = createSelector(
    [
        (state) => state.order.get('templatesIndexTable'),
        (state) => state.order.get('byId'),
        (state) => state.currentUser.get('language'),
        (state) => state.productCached.get('byId'),
    ],
    (indexTable, byId, language, referencedProductsById) => ({
        loading: indexTable.get('loading'),
        count: indexTable.get('count'),
        items: parseMultipleFromReference(indexTable.get('items'), byId, (i) => parseOrder(i, language, referencedProductsById)),
    })
)

const selectActiveOrder = createSelector(
    [
        (state) => state.order.get('byId'),
        (state) => state.order.get('activeOrder'),
        (state) => state.currentUser.get('language'),
        (state) => state.productCached.get('byId'),
    ],
    (byId, activeOrder, language, referencedProductsById) => parseMultipleFromReference(activeOrder, byId, (i) => parseOrder(i, language, referencedProductsById)),
)

const selectOrderBalance = createSelector(
    (state) => state.order.get('orderBalance'),
    (datum) => ({
        totalPurchase: datum.get('totalPurchase'),
        totalRemainder: datum.get('totalRemainder'),
    }),
)

export const useOrderData = () => {
    const cloneOrder = useSelector(selectCloneOrder)
    const loading = useSelector(selectLoading)
    const indexTable = useSelector(selectIndexTable)
    const templatesIndexTable = useSelector(selectTemplatesIndexTable)
    const activeOrder = useSelector(selectActiveOrder)
    const creatingOrder = useSelector(selectCreatingOrder)
    const orderBalance = useSelector(selectOrderBalance)
    return {
        loading,
        indexTable,
        creatingOrder,
        activeOrder,
        cloneOrder,
        templatesIndexTable,
        orderBalance
    }
}