// @flow
import React, {useContext} from 'react';
import Util from "../../components/util/util";
import Title from "../../components/title/title";
import Divider from "../../components/divider/divider";
import "./orders.css"
import Button from "../../components/button/button";
import Table from "../../components/table/table";
//import {Link} from "react-router-dom";
import {getRouteUrl} from "../../helpers/getRouteUrl";
import {
    // ROUTE_PAGE_ONLINE_PAYMENTS,
    // ROUTE_PAGE_ORDER_FINALIZE,
    // ROUTE_PAGE_ORDER_TEMPLATES,
    ROUTE_PAGE_ORDER,
    ROUTE_PAGE_ORDER_EDIT,

    ROUTE_PAGE_PRODUCT_CATEGORY_PARENT
} from "../../routers/routes";
import useOrderAction from "../../hooks/order/useOrderAction";
import {useOrderData} from "../../hooks/order/useOrderData";
import {formatDate, formatTypes} from "../../helpers/date";
import {OrderStatus as OrderStatusTypes, Permissions} from "../../helpers/constants";
import SelectStore from "../../components/selectStore/selectStore";
//import Images from "../../assets/images";
import CreateOrder from "../../components/createOrder/createOrder";
import {useTranslation} from "react-i18next";
import {PAGE_ORDERS} from "../../locales/pages/namespaces";
import OrderStatus from "../../components/orderStatus/orderStatus";
import Loading from "../../components/loading/loading";
import OrderType from "../../components/orderType/orderType";
import {MenuContext} from "../main/main";
import {useHistory} from "react-router";
// import {useOrderBalance} from "../../hooks/order/useOrderBalance";
// import {useUserData} from "../../hooks/user/useUserData";
import {useHasPermission} from "../../modules/permission/hooks/useHasPermission";

const Orders = () => {
    const {t} = useTranslation(PAGE_ORDERS)
    const isAdmin = useHasPermission([Permissions.AccessAdmin], {shouldView: true})
    const isSalesPerson = useHasPermission([Permissions.AccessSalesPerson], {shouldView: true})
    const {getOrders} = useOrderAction()
    const {indexTable, loading} = useOrderData()
    const menu = useContext(MenuContext)
    const history = useHistory()
    //const {data} = useUserData()
    //const {totalRemainder, totalPurchase} = useOrderBalance()

    return (
        <div className={'orders-page contained-page'}>
            <div>
                <Loading show={loading}/>
                <Util>
                    <Title variant={"h2"} sup={t('view_2')}>
                        {t('view_1')}
                    </Title>
                    <div className="hide-mobile">
                        {!isAdmin && !isSalesPerson && <SelectStore/>}
                    </div>
                </Util>
                <Divider/>
                <div className="show-mobile" style={{textAlign: 'right'}}>
                    <br/>
                    {!isAdmin && !isSalesPerson && <SelectStore/>}
                </div>
                {/*<div className="hide-mobile">*/}
                {/*    <br/>*/}
                {/*</div>*/}
                <div className={'orders-page__main'}>
                    {/*<div className={'wrapper-1'}>*/}
                    {/*    <img*/}
                    {/*        src={Images.pages.orders.background}*/}
                    {/*        alt="background"*/}
                    {/*        className={'background show-mobile'}*/}
                    {/*    />*/}
                    {/*    <PermissionHOC requiredPermissions={[Permissions.AccessReports]}>*/}
                    {/*        <div>*/}
                    {/*            */}
                    {/*            */}
                    {/*            <div className="hide-mobile">*/}
                    {/*                <Divider/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </PermissionHOC>*/}
                    {/*</div>*/}
                    <div>
                        <Button.Group expandedButtons className={'wrap-when-more-than-3'}>
                            <CreateOrder/>
                        </Button.Group>
                        <Table
                            data={indexTable}
                            pageSize={10}
                            dataManager={getOrders}
                            noHeader
                            getRowProps={(row) => row.original.statusId === OrderStatusTypes.Change ? (((row.original.orderSheetIsInternal && !isAdmin && !isSalesPerson) || (!row.original.orderSheetIsInternal && isSalesPerson)) ? {
                                className: 'submit-order'
                            } : {
                                className: 'current-order'
                            }) : {}}
                            columns={[
                                {
                                    accessor: 'code',
                                    Cell: ({value}) => (
                                        <strong>#{value}</strong>
                                    )
                                },
                                {
                                    accessor: 'date',
                                    Cell: ({value}) => (
                                        <div>
                                            <strong>{formatDate(value, formatTypes.TITLE_HALF)} </strong>
                                            <span>({formatDate(value, formatTypes.TIME_ONLY)})</span>
                                        </div>
                                    )
                                },
                                {
                                    accessor: 'type',
                                    Cell: ({value, row}) => (
                                        <OrderType
                                            type={value}
                                            typeDescription={row.original.typeDescription}
                                        />
                                    )
                                },
                                // {
                                //     accessor: 'price',
                                //     Cell: ({value}) => <strong>{value}{data?.currencySymbol}</strong>
                                // },
                                {
                                    accessor: 'statusId',
                                    Cell: ({value, row}) => {
                                        const {transferredDate, id, transitionDate} = row.original
                                        return (
                                            <div {...value === OrderStatusTypes.Change ?
                                                {
                                                    style: {cursor: 'pointer', textAlign: "center"},
                                                    onClick: () => {
                                                        if ((row.original.orderSheetIsInternal && !isAdmin && !isSalesPerson) || (!row.original.orderSheetIsInternal && isSalesPerson)) {
                                                            history.push(getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {id: id}) + '/finalize', {order: row.original})
                                                        } else {
                                                            menu.setMenuCategoryType(row.original.type)
                                                            history.push(getRouteUrl(ROUTE_PAGE_PRODUCT_CATEGORY_PARENT, {
                                                                id: 'all',
                                                            }))
                                                        }
                                                        // menu.setOpenMenu(true)
                                                    }
                                                } : {style: {cursor: 'pointer', textAlign: "center"}}}>
                                                <div style={{textAlign: "center"}}>
                                                    <OrderStatus status={value}
                                                                 finalize={value === OrderStatusTypes.Change && ((row.original.orderSheetIsInternal && !isAdmin && !isSalesPerson) || (!row.original.orderSheetIsInternal && isSalesPerson))}/>
                                                </div>
                                                {transferredDate && (
                                                    <div style={{
                                                        fontSize: '.7rem',
                                                        textAlign: "center",
                                                        whiteSpace: "nowrap"
                                                    }}>
                                                        (<strong>{formatDate(transferredDate, formatTypes.DATE_ONLY)} </strong> - {formatDate(transferredDate, formatTypes.TIME_ONLY_NO_SEC)})
                                                    </div>
                                                )}
                                                {transitionDate && (
                                                    <div style={{
                                                        fontSize: '.7rem',
                                                        textAlign: "center",
                                                        whiteSpace: "nowrap"
                                                    }}>
                                                        (<strong>{formatDate(transitionDate, formatTypes.DATE_ONLY)} </strong> - {formatDate(transitionDate, formatTypes.TIME_ONLY_NO_SEC)})
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                },
                                {
                                    accessor: 'id',
                                    Cell: ({value, row}) => {
                                        return (
                                            <div
                                                className={'order-status order-status--0'}
                                                style={{cursor: 'pointer', textAlign: "center"}}
                                                onClick={() => {
                                                    if (row.original.statusId === OrderStatusTypes.Change) {
                                                        if ((row.original.orderSheetIsInternal && !isAdmin && !isSalesPerson) || (!row.original.orderSheetIsInternal && isSalesPerson)) {
                                                            history.push(getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {id: value}) + '/finalize', {order: row.original})
                                                        } else {
                                                            history.push(getRouteUrl(ROUTE_PAGE_ORDER_EDIT, {
                                                                id: value,
                                                            }))
                                                        }
                                                    } else {
                                                        history.push(getRouteUrl(ROUTE_PAGE_ORDER, {
                                                            id: value,
                                                        }))
                                                    }
                                                }}
                                            >
                                                {t('show')}
                                            </div>
                                        )
                                    }
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Orders;