//@flow
import React from 'react';
import './searchPopup.css'
import classNames from "classnames";
import Button from "../button/button";
import Icon from "../icon/icon";
import Images from "../../assets/images";
import SearchInput from "../searchInput/searchInput";
import useSearchQuery from "../../hooks/useSearchQuery";

type Props = {
    visible: boolean,
    onSubmit: Function,
    onClose: Function,
};

const SearchPopup = (props: Props) => {
    const {query, setQuery} = useSearchQuery()
    const {onSubmit, onClose, visible} = props;
    return (
        <div className={classNames('search-popup', {
            'search-popup--visible': visible
        })}>
            <div className={'search-popup__background'}>
                <Button className={'search-popup__close'} variant={"icon"} onClick={onClose}>
                    <Icon icon={Images.icons.close}/>
                </Button>
            </div>
            <SearchInput
                query={query}
                onChange={setQuery}
                onSubmit={onSubmit}
            />
        </div>
    );
};

export default SearchPopup;