import type {CustomerTabInfo, Report1Info, Report4Info, Report5Info, Store, User} from "./types";
import {parseMultiple} from "../../helpers/parse";
import {isImmutable} from "immutable";
import moment from "moment";

export const parseStore: Store = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        address: datum.getIn(['address', language]),
        countryCode: datum.get('countryCode'),
        erpCode: datum.get('erpCode'),
        isAdmin: datum.get('isAdmin'),
        customerErpCode: datum.get('customerErpCode'),
        ciIdCode: datum.get('ciIdCode')
    }
}

const parseOrderType = (datum, language) => {
    if (!(datum && isImmutable(datum))) return
    return {
        id: datum.get('id'),
        title: datum.getIn(['title', language]),
    }
}

export const parseUser: User = (datum, language) => {
    if (!datum) return;
    return {
        id: datum.get('id'),
        loginName: datum.getIn(['loginName', language]),
        username: datum.get('username'),
        erpCode: datum.get('erpCode'),
        currencySymbol: datum.get('currencySymbol'),
        locale: datum.get('locale'),
        orderTypes: parseMultiple(datum.get('orderTypes'), (i) => parseOrderType(i, language)),
        routeStartDate: datum.get('routeStartDate'),
        routeRepetition: datum.get('routeRepetition'),
    }
}

const parseTransaction = (datum) => {
    if (!datum) return
    return {
        date: datum.get('date'),
        remainder: datum.get('remainder'),
        documentNumber: datum.get('documentNumber'),
        debitAmount: datum.get('debitAmount'),
        creditAmount: datum.get('creditAmount'),
        branch: datum.get('branch'),
        reason: datum.get('reason'),
    }
}

export const parseCustomerTabInfo: CustomerTabInfo = (datum, language) => {
    if (!datum) return;
    return {
        info: {
            name: datum.getIn(['info', 'name']),
            address: datum.getIn(['info', 'address']),
            postalCode: datum.getIn(['info', 'postalCode']),
            vatNumber: datum.getIn(['info', 'vatNumber']),
            area: datum.getIn(['info', 'area']),
            occupation: datum.getIn(['info', 'occupation']),
            city: datum.getIn(['info', 'city']),
            contact: datum.getIn(['info', 'contact']),
            phone: datum.getIn(['info', 'phone']),
        },
        transactionData: parseMultiple(datum.get('transactionData'), parseTransaction),
        pending: parseMultiple(datum.get('pending'), parseTransaction),
        transactionDataTotal: {
            remainder: datum.getIn(['transactionDataTotal', 'remainder']),
            debitAmount: datum.getIn(['transactionDataTotal', 'debitAmount']),
            creditAmount: datum.getIn(['transactionDataTotal', 'creditAmount']),
        },
        pendingTotal: {
            pendingQuantity: datum.getIn(['pendingTotal', 'pendingQuantity']),
            predictedPendingValue: datum.getIn(['pendingTotal', 'predictedPendingValue']),
        }
    }
}

export const parseReport1Info: Report1Info = (datum, language) => {
    if (!datum) return
    const hasSubcategory = datum.getIn(['subCategory', 'title', language])
    return ({
        id: datum.get('id'),
        category: {
            id: datum.getIn(['category', 'íd']),
            title: datum.getIn(['category', 'title', language])
        },
        subCategory: {
            id: datum.getIn(['subCategory', 'íd']),
            title: hasSubcategory ?
                datum.getIn(['subCategory', 'title', language]) : '-',
        },
        description: datum.getIn(['description', language]),
        price: datum.get('price'),
        quantity: datum.get('quantity'),
    })
}

export const parseReport2Info: Report1Info = (datum, language) => {
    if (!datum) return
    const hasSubcategory = datum.getIn(['subCategory', 'title', language])
    return ({
        id: datum.get('id'),
        category: {
            id: datum.getIn(['category', 'íd']),
            title: datum.getIn(['category', 'title', language])
        },
        subCategory: {
            id: datum.getIn(['subCategory', 'íd']),
            title: hasSubcategory ?
                datum.getIn(['subCategory', 'title', language]) : '-',
        },
        description: datum.getIn(['description', language]),
        price: datum.get('price'),
        date: datum.get('date'),
        quantity: datum.get('quantity'),
    })
}

export const parseReport3Info: Report1Info = (datum, language) => {
    if (!datum) return
    const hasSubcategory = datum.getIn(['subCategory', 'title', language])
    return ({
        id: datum.get('id'),
        category: {
            id: datum.getIn(['category', 'íd']),
            title: datum.getIn(['category', 'title', language])
        },
        subCategory: {
            id: datum.getIn(['subCategory', 'íd']),
            title: hasSubcategory ?
                datum.getIn(['subCategory', 'title', language]) : '-',
        },
        description: datum.getIn(['description', language]),
        period1: {
            price: datum.getIn(['period1', 'price']),
            quantity: datum.getIn(['period1', 'quantity']),
        },
        period2: {
            price: datum.getIn(['period2', 'price']),
            quantity: datum.getIn(['period2', 'quantity']),
        },
    })
}

export const parseReport4Info: Report4Info = (datum, language) => {
    if (!datum) return
    return ({
        address: datum.get('address'),
        countryCode: datum.get('countryCode'),
        date: datum.get('date'),
        salesPersonName: datum.get('salesPersonName'),
        customer: datum.get('customer'),
        orderSheet: {
            id: datum.getIn(['orderSheet', 'id']),
            description: datum.getIn(['orderSheet', 'description', language])
        },
        id: datum.get('id'),
        transferredDate: datum.get('transferredDate'),
        transitionDate: datum.get('transitionDate'),
        statusId: datum.get('statusId'),
        price:  datum.get('price'),
        customerErpCode: datum.get('customerErpCode'),
        addressErpCode: datum.get('addressErpCode'),
        ciIdCode: datum.get('ciIdCode')
    })
}

export const parseReport5Info: Report5Info = (datum, language) => {
    if (!datum) return
    return ({
        code: datum.get('code'),
        date: datum.get('date'),
        description: datum.getIn(['description', language]),
        id: datum.get('id'),
        productId: datum.get('productId'),
        statusId: datum.get('statusId'),
        quantity: datum.get('quantity'),
        transferredDate: datum.get('transferredDate'),
        transitionDate: datum.get('transitionDate'),
    })
}